import { FormTabConfig } from '../../components/MultiTabForm/MultiTabForm';
import { formState } from '../../components/useFormControls';
import * as yup from 'yup';
import { TypographyContainerFormTabs } from '../../components/MultiTabForm/styles';

export const identificationTabState: formState = {
  cpfOrTelephone: '',
};

export const useIdentificationTab = () => {
  const identificationTab: FormTabConfig = {
    formState: identificationTabState,
    mandatory: true,
    label: (
      <TypographyContainerFormTabs>
        Pra finalizar a sua solicitação, precisamos verificar se você já possui
        um cadastro com a gente.
      </TypographyContainerFormTabs>
    ),
    formConfig: [
      {
        key: 'cpfOrTelephone',
        type: 'TextInput',
        size: 12,
        validation: yup.string().required('Campo Obrigatório'),
        onlyNumber: true,
        textFieldProps: {
          label: 'CPF ou Celular',
          required: true,
          type: 'number',
        },
      },
    ],
  };

  return { identificationTab } as const;
};
