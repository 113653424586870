import {
  Autocomplete,
  AutocompleteInputChangeReason,
  InputAdornment,
  TextFieldProps,
} from '@mui/material';
import { useEffect, useRef } from 'react';

import TextInput from '../TextInput/TextInput';

export type dropdownOptions = { name: string; id: number | string };

interface DropdownProps {
  value: dropdownOptions;
  name: string;
  options: dropdownOptions[];
  textFieldProps?: TextFieldProps;
  onChange: any;
}

const getOptionLabel = (dropdownOptions: string | dropdownOptions) =>
  typeof dropdownOptions === 'string' ? '' : dropdownOptions.name;

const Dropdown: React.FC<DropdownProps> = ({
  value,
  name,
  options,
  textFieldProps,
  onChange,
}) => {
  const selectedValue = value ? options.find(e => e.id === value.id) : '';
  const inputAdornment = textFieldProps?.InputProps?.startAdornment;
  const firstRender = useRef(true);

  const createTargetEvent = (value: string | dropdownOptions | null) =>
    ({
      target: {
        value,
        name,
      },
    } as any);

  useEffect(() => {
    if (firstRender.current) firstRender.current = false;
  }, []);

  const handleChange = (
    e: React.ChangeEvent<{}>,
    value: string | dropdownOptions | null
  ) => {
    if (!!value) {
      const event = createTargetEvent(value);
      onChange(event);
    }
  };

  const onInputChange = (
    e: React.ChangeEvent<{}>,
    value: string | dropdownOptions | null,
    reason: AutocompleteInputChangeReason
  ) => {
    if (reason === 'clear' && !firstRender.current) {
      const event = createTargetEvent('');
      onChange(event);
    }
  };

  return (
    <Autocomplete
      options={options}
      getOptionLabel={getOptionLabel}
      value={selectedValue}
      style={{ display: 'flex' }}
      onInputChange={onInputChange}
      onChange={handleChange}
      renderInput={params => (
        <TextInput
          name={name}
          textFieldProps={{
            ...params,
            ...textFieldProps,
            InputProps: {
              ...params.InputProps,
              startAdornment: !!inputAdornment && (
                <>
                  <InputAdornment position='end'>
                    {inputAdornment}
                  </InputAdornment>
                  {params.InputProps.startAdornment}
                </>
              ),
            },
          }}
        />
      )}
    />
  );
};

export default Dropdown;
