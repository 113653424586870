import { ElementType } from 'react';

import { StyledTextField } from './styles';
import TextInputMask, { Mask } from '../TextInputMask/TextInputMask';
import { InputBaseComponentProps, TextFieldProps } from '@mui/material';

const insertMaskElement = (mask?: Mask, textFieldProps?: TextFieldProps) => {
  if (!!mask) {
    if (!textFieldProps) {
      textFieldProps = {};
    }
    if (!textFieldProps?.InputProps) {
      textFieldProps.InputProps = {};
    }
    if (!textFieldProps.InputProps.inputProps) {
      textFieldProps.InputProps.inputProps = {};
    }
    textFieldProps.InputProps.inputComponent =
      TextInputMask as ElementType<InputBaseComponentProps>;
    textFieldProps.InputProps.inputProps.mask = mask;
  }
};

export interface TextInputProps {
  name?: string;
  value?: string | number | null;
  onChange?: any;
  textFieldProps?: TextFieldProps;
  mask?: Mask;
  onlyNumber?: boolean;
  inputRefs?: any;
  nextInput?: any;
}

const TextInput: React.FC<TextInputProps> = ({
  name,
  value,
  onChange,
  textFieldProps,
  mask,
  onlyNumber,
  inputRefs,
  nextInput,
}) => {
  insertMaskElement(mask, textFieldProps);

  return (
    <StyledTextField
      name={name}
      inputRef={ref => {
        if (name && inputRefs && inputRefs.current)
          inputRefs.current[name] = ref;
      }}
      value={value === undefined && textFieldProps?.label ? '' : value}
      onChange={(event: any) => {
        if (onlyNumber) {
          event.target.value = event.target?.value?.replace(/[^0-9]/g, '') ?? 0;
          if (event.target.value.length > 0) {
            onChange(event);
          } else {
            event.target.value = null;
            onChange(event);
          }
        } else onChange(event);
      }}
      {...textFieldProps}
    />
  );
};

export default TextInput;
