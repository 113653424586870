import { Close } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
} from '@mui/material';
import { Dispatch, SetStateAction } from 'react';

type dialogAction = {
  style: any;
  label: string;
  onClick: () => {};
};

interface SimpleDialogProps extends DialogProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  dialogTitle: string;
  dialogContent?: any;
  dialogActions?: dialogAction[];
}

const SimpleDialog = ({
  open,
  dialogTitle,
  setOpen,
  dialogContent,
  dialogActions,
}: SimpleDialogProps) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog fullWidth maxWidth='lg' onClose={handleClose} open={open}>
      <DialogTitle
        style={{
          fontFamily: 'Roboto',
          fontWeight: '400 Regular',
          fontSize: 34,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {dialogTitle}{' '}
        <Close
          onClick={handleClose}
          style={{ marginRight: 5, marginLeft: 'auto', cursor: 'pointer' }}
        />
      </DialogTitle>
      <DialogContent>{dialogContent}</DialogContent>
      <DialogActions>
        {dialogActions?.map((action, index) => (
          <Button key={index} onClick={action.onClick} style={action.style}>
            {action.label}
          </Button>
        ))}
      </DialogActions>
    </Dialog>
  );
};

export default SimpleDialog;
