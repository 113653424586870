import { Button, Tabs } from '@mui/material';
import styled from 'styled-components';

import { ReactComponent as Success } from '../../assets/images/success.svg';

export const MultiStepFormContainer = styled.div`
  width: 80%;
  margin: auto;
  margin-top: 50px;
  max-width: 700px;

  &.mobile {
    width: 100%;
    margin-top: -10%;
  }
`;

export const MultiStepFormTabs = styled(Tabs)`
  margin: 10px;
`;

export const FormContainerFormTabs = styled.div`
  position: relative;
  background: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  padding: 30px;
  margin: 10px;
`;

export const TypographyContainerFormTabs = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
  text-align: left;
`;

export const ButtonContainerFormTabs = styled.div`
  display: flex;
  margin-top: 60px;
`;

export const MultiTabFormTitle = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  align-self: center;
  font-family: Roboto;
  font-size: 34px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0px;
  text-align: center;

  font-family: Roboto;
  font-size: 34px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0px;
  text-align: center;
`;

export const MultiTabFormSubTitle = styled.p`
  margin-top: 10px;
  margin-bottom: 10px;
  width: 70%;
  align-self: center;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15000000596046448px;
  text-align: center;

  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15000000596046448px;
  text-align: center;
`;

export const PreviousButton = styled(Button)`
  margin-left: 0;
  margin-right: auto;
  height: 38px;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-align: center;
`;

export const NextButton = styled(Button)`
  margin-left: auto;
  margin-right: 0;
  height: 38px;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-align: center;
`;

export const VerifyButton = styled(Button)`
  width: 100%;
  height: 38px;
  margin-top: 25px !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-align: center;
`;

export const FormButton = styled(Button)`
  width: 100%;
  height: 38px;
  margin-top: 25px !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-align: center;
`;

export const TabDiv = styled.div`
  display: flex;
  margin: auto;
  justify-content: space-evenly;
  margin-top: 2%;
  margin-bottom: -3%;
  max-width: 800px;

  .tabDiv {
    border-radius: 15px;
    padding: 7px 15px;
    color: #939598;

    p {
      font-weight: 600;
    }

    &.active {
      background: #ffffff;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      color: #0446f5;
    }
  }
`;

export const TitleSuccesTab = styled.h3`
  font-size: 40px;
  font-weight: 400;
  line-height: 1.15;
  color: #0446f5;
  text-align: center;
  margin-bottom: 15px;

  @media (max-width: 992px) {
    font-size: 32px;
  }
`;

export const SubtitleSuccesTab = styled.p`
  font-size: 26px;
  font-weight: 500;
  line-height: 1.35;
  color: #4d4d4d;
  text-align: center;
  margin-bottom: 15px;

  @media (max-width: 992px) {
    font-size: 20px;
  }
`;

export const TextSuccesTab = styled.p`
  color: #58595b;
  font-weight: 400;
  font-size: 15px;
  line-height: 1.5;
  margin-bottom: 25px;
`;

export const SuccessImage = styled(Success)`
  max-width: 95%;
`;
