import { useEffect } from 'react';
import { FormTabConfig } from '../../components/MultiTabForm/MultiTabForm';
import { TypographyContainerFormTabs } from '../../components/MultiTabForm/styles';
import { formState } from '../../components/useFormControls';
import { api } from '../../services/Api';
import Countdown from 'react-countdown';

export const confirmationTabState: formState = {
  tokenDigit1: null,
  tokenDigit2: null,
  tokenDigit3: null,
  tokenDigit4: null,
};

const formatPhone = (phone: string) => {
  return `(${phone[0]}${phone[1]}) ${phone[2]} **** - ${phone[7]}${phone[8]}${phone[9]}${phone[10]}`;
};

export const useConfirmationTab = (
  celular: string,
  clientId: string,
  sendTokenAgain: any,
  setSendTokenAgain: any,
  inputRefs: any
) => {
  // const fetchToken = async (celular: string, clientId: string) => {
  //   try {
  //     await api.post('/landing-page/token', {
  //       phone: celular.replace(/\D/g, ''),
  //       idClient: clientId,
  //     });
  //   } catch (e: any) {
  //     alert(e.response.data.message.error);
  //   }
  // };

  // useEffect(() => {
  //   if (celular && clientId && sendTokenAgain) {
  //     fetchToken(celular, clientId);
  //     setSendTokenAgain(false);
  //   }
  // }, [celular, clientId, sendTokenAgain, setSendTokenAgain]);

  // const renderer = ({ minutes, seconds }: any) => {
  //   return (
  //     <p style={{ color: '#FF7643' }}>
  //       {'0' + minutes}:{seconds ? seconds : '0' + seconds}
  //     </p>
  //   );
  // };

  const confirmationTab: FormTabConfig = {
    formState: {},
    mandatory: true,
    label: (
      <>
        <TypographyContainerFormTabs>
          {/* Para concluir a sua solicitação, enviamos um SMS para o seu celular
          com um código validador. Por favor, insira o código nos campos a
          seguir. */}
          Deseja concluir sua solicitação?
        </TypographyContainerFormTabs>
        {/* <div style={{ display: 'flex' }}>
          <TypographyContainerFormTabs
            style={{ marginLeft: 0, marginRight: 'auto' }}
          >
            Celular: <p style={{ color: '#FF7643' }}>{formatPhone(celular)}</p>
          </TypographyContainerFormTabs>{' '}
          <TypographyContainerFormTabs>
            Código expira em:
            <Countdown date={Date.now() + 60000} renderer={renderer} />
          </TypographyContainerFormTabs>
        </div> */}
      </>
    ),
    formConfig: [
      // {
      //   key: 'tokenDigit1',
      //   type: 'TextInput',
      //   size: 3,
      //   onlyNumber: true,
      //   nextInput: inputRefs ? inputRefs.current['tokenDigit2'] : undefined,
      //   textFieldProps: {
      //     inputProps: {
      //       maxLength: 1,
      //       style: { textAlign: 'center', color: '#FF7643' },
      //     },
      //   },
      // },
      // {
      //   key: 'tokenDigit2',
      //   type: 'TextInput',
      //   size: 3,
      //   onlyNumber: true,
      //   nextInput: inputRefs ? inputRefs.current['tokenDigit3'] : undefined,
      //   textFieldProps: {
      //     inputProps: {
      //       maxLength: 1,
      //       style: { textAlign: 'center', color: '#FF7643' },
      //     },
      //   },
      // },
      // {
      //   key: 'tokenDigit3',
      //   type: 'TextInput',
      //   size: 3,
      //   onlyNumber: true,
      //   nextInput: inputRefs ? inputRefs.current['tokenDigit4'] : undefined,
      //   textFieldProps: {
      //     inputProps: {
      //       maxLength: 1,
      //       style: { textAlign: 'center', color: '#FF7643' },
      //     },
      //   },
      // },
      // {
      //   key: 'tokenDigit4',
      //   type: 'TextInput',
      //   size: 3,
      //   onlyNumber: true,
      //   textFieldProps: {
      //     inputProps: {
      //       maxLength: 1,
      //       style: { textAlign: 'center', color: '#FF7643' },
      //     },
      //   },
      // },
    ],
  };

  return { confirmationTab } as const;
};
