import { TypographyProps } from '@mui/material';
import { StyledTypography } from './styles';

interface LabelProps extends TypographyProps {
  label: string;
}

const Label = ({ label }: LabelProps) => {
  return <StyledTypography>{label}</StyledTypography>;
};

export default Label;
