import { FormTabConfig } from '../../components/MultiTabForm/MultiTabForm';
import { formState } from '../../components/useFormControls';
import { periods } from '../../constants/DropdownOptions/dropdownOptions';
import * as yup from 'yup';
import { TypographyContainerFormTabs } from '../../components/MultiTabForm/styles';

export const serviceDateTabState: formState = {
  solicitationDate: null,
  period: null,
  othersInfos: '',
};

export const useServiceDateTab = () => {
  const serviceDateTab: FormTabConfig = {
    formState: serviceDateTabState,
    mandatory: true,
    label: (
      <TypographyContainerFormTabs>
        Sugira uma data para a realização do serviço e aproveite para
        acrescentar mais informações.
      </TypographyContainerFormTabs>
    ),
    formConfig: [
      {
        key: 'solicitationDate',
        type: 'DatePicker',
        size: 12,
        validation: yup
          .date()
          .min(new Date(), 'Data Inválida')
          .required('Campo Obrigatório')
          .typeError('Data Inválida'),
        required: true,
        label: 'Data',
      },
      {
        key: 'period',
        type: 'Dropdown',
        size: 12,
        options: periods,
        textFieldProps: {
          label: 'Período',
        },
      },
      {
        key: 'othersInfos',
        type: 'TextInput',
        size: 12,
        textFieldProps: {
          label: 'Outras Informações',
          multiline: true,
          inputProps: {
            maxLength: 200,
          },
        },
      },
    ],
  };

  return { serviceDateTab } as const;
};
