import { FormTabConfig } from '../../components/MultiTabForm/MultiTabForm';
import {
  TitleSuccesTab,
  SubtitleSuccesTab,
  TextSuccesTab,
  SuccessImage,
} from '../../components/MultiTabForm/styles';

export const useFinalTab = () => {
  const finalTab: FormTabConfig = {
    formState: {},
    mandatory: true,
    label: (
      <>
        <TitleSuccesTab>Parabéns!</TitleSuccesTab>
        <SubtitleSuccesTab>Orçamento realizado com sucesso!</SubtitleSuccesTab>

        <TextSuccesTab>
          Você concluiu sua solicitação. Aguarde para receber até 3<br />
          orçamentos de profissionais!
        </TextSuccesTab>
        <SuccessImage />
      </>
    ),
    formConfig: [],
  };

  return { finalTab } as const;
};
