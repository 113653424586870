import { ChangeEvent, useCallback, useState } from 'react';

interface ChangeObject {
  target: {
    value: any;
    name: string;
    [key: string]: any;
  };
}

export type formState = { [key: string]: any };
export type errorState = { [key: string]: string };

export type formControlsChangeEvent =
  | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  | ChangeObject;

export const changeObjectValue = <T>(state: T, key: string, value: any) =>
  ({
    ...state,
    [key]: value,
  } as T);

export const useFormControls = <T extends formState>(
  initialState: T,
  formIndex?: number
) => {
  const [formValues, setFormValues] = useState<T>(initialState);
  const handleFormChange = useCallback(
    (e: formControlsChangeEvent) => {
      const { name, value } = e.target;
      if (Array.isArray(formValues)) {
        setFormValues(s =>
          s.map((e: formState, i: number) =>
            i === formIndex ? changeObjectValue(e, name, value) : e
          )
        );
      } else {
        setFormValues(s => changeObjectValue(s, name, value));
      }
    },
    [formValues, formIndex]
  );

  return {
    formValues,
    setFormValues,
    handleFormChange,
  };
};
