import { colors } from './colors';
import { createTheme } from '@mui/material';

export const CustomThemeElements = {
  colors,
};

export const Theme = createTheme({
  palette: {
    primary: {
      main: colors.text.primary,
      contrastText: 'white',
    },
    secondary: {
      main: colors.text.emphasys,
      contrastText: 'white',
    },
  },
});

export const FormTheme = createTheme({
  palette: {
    primary: {
      main: '#0446F5',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#F2F2F2',
      contrastText: '#4D4D4D',
    },
  },
});
