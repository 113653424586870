import { Typography } from '@mui/material';
import styled from 'styled-components';

export const UploadDiv = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 10px;
`;

export const UploadTitle = styled(Typography)`
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 150%;

  letter-spacing: 0.4px;

  color: #58595b;
`;

export const UploadDescription = styled(Typography)`
  font-style: normal;
  font-weight: 200;
  font-size: 14px;
  line-height: 150%;
  text-align: left;

  letter-spacing: 0.4px;

  color: #000000;
`;

export const FileName = styled(Typography)`
  font-style: normal;
  font-weight: 200;
  font-size: 14px;
  line-height: 150%;
  text-align: left;

  letter-spacing: 0.4px;

  color: #000000;

  display: flex;
  width: 90%;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
`;

export const Divider = styled.div`
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
  border-bottom: 0.063rem solid #f4f4f4;
  width: 90%;
`;

export const FileErrorMessage = styled(Typography)`
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 150%;
  margin-left: 1.9rem;

  letter-spacing: 0.4px;

  color: #d80b1a;
`;

export const FileNameDiv = styled.div`
  display: flex;
  align-items: center;
`;
