export const paintingType = [
  { id: 0, name: 'Pintura Nova' },
  { id: 1, name: 'Repintura' },
];

export const propertyType = [
  { id: 0, name: 'Casa' },
  { id: 1, name: 'Apartamento' },
  { id: 2, name: 'Comércio' },
];

export const coatingType = [
  { id: 0, name: 'Alvenaria' },
  { id: 1, name: 'Madeira' },
  { id: 2, name: 'Metálica' },
  { id: 3, name: 'Não Sei Informar' },
];

export const textureType = [
  { id: 0, name: 'Sem Textura' },
  { id: 1, name: 'Com Textura' },
];

export const gateOrGrillMaterial = [
  { id: 0, name: 'Alumínio' },
  { id: 1, name: 'Madeira' },
  { id: 2, name: 'Ferro' },
  { id: 3, name: 'Outro' },
];

export const hydraulicServiceType = [
  { id: 0, name: 'Substituição' },
  { id: 1, name: 'Reparo' },
  { id: 2, name: 'Manutenção' },
  { id: 3, name: 'Instalação' },
  { id: 4, name: 'Visita Técnica' },
];

export const plumbingType = [
  { id: 0, name: 'Embutido na Parede' },
  { id: 1, name: 'Fora da Parede' },
  { id: 2, name: 'Não Sei Informar' },
];

export const washingMachineType = [
  { id: 0, name: 'Abertura Frontal' },
  { id: 1, name: 'Abertura Superior' },
];

export const outletAccess = [
  { id: 0, name: 'Tem Acesso' },
  { id: 1, name: 'Não Tem Acesso' },
  { id: 2, name: 'Não Sei Informar' },
];

export const drainAccess = [
  { id: 0, name: 'Tem Acesso' },
  { id: 1, name: 'Não Tem Acesso' },
  { id: 2, name: 'Não Sei Informar' },
];

export const electricianServiceType = [
  { id: 0, name: 'Substituição' },
  { id: 1, name: 'Manutenção' },
  { id: 2, name: 'Instalação' },
  { id: 3, name: 'Visita Técnica' },
];

export const switchLocation = [
  { id: 0, name: 'Interruptor na Parede' },
  { id: 1, name: 'Não Possui Interruptor' },
  { id: 2, name: 'Não Sei Informar' },
];

export const outletInstallation = [
  { id: 0, name: 'Nova Tomada' },
  { id: 1, name: 'Substituição de Tomada' },
];

export const infrastructure = [
  { id: 0, name: 'Infraestrutura Pronta' },
  { id: 1, name: 'Sem Infraestrutura Pronta' },
];

export const airConditionalType = [
  { id: 0, name: 'Split' },
  { id: 1, name: 'De Janela' },
  { id: 2, name: 'Não Sei Informar' },
];

export const externalProtection = [
  { id: 0, name: 'Precisa Instalar' },
  { id: 1, name: 'Não Precisa Instalar' },
  { id: 2, name: 'Não Sei Informar' },
];

export const btuPower = [
  { id: 0, name: 'Até 9.000' },
  { id: 1, name: '12.000' },
  { id: 2, name: '18.000' },
  { id: 3, name: '24.000' },
  { id: 4, name: 'Acima de 24.000' },
  { id: 5, name: 'Não Sei Informar' },
];

export const periods = [
  { id: 0, name: 'Manhã' },
  { id: 1, name: 'Tarde' },
  { id: 2, name: 'Noite' },
];
