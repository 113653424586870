import styled from 'styled-components';
import { Button, TextField } from '@mui/material';

export const StyledTextField = styled(TextField)`
  width: 100%;
  margin-bottom: 15px;
`;

export const SaveButton = styled(Button)`
  margin-left: auto;
  margin-right: 0;
  height: 38px;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-align: center;
`;
