import { Grid } from '@mui/material';
import ButtonGroup from '../ButtonGroup/ButtonGroup';
import CustomCheckbox from '../CheckboxGroup/CheckboxGroup';
import DatePicker from '../DatePicker/DatePicker';
import Dropdown from '../Dropdown/Dropdown';
import { formConfig } from '../Form';
import RadioButtonsGroup from '../RadioButtonGroup/RadioButtonGroup';

import TextInput from '../TextInput/TextInput';

import { formState, formControlsChangeEvent } from '../../useFormControls';
import Label from '../Label/Label';
import Upload from '../Upload/Upload';

const DATE_TIME = 'DatePicker';
const DROPDOWN = 'Dropdown';
const TEXT_INPUT = 'TextInput';
const BUTTON_GROUP = 'ButtonGroup';
const CHECKBOX = 'CheckboxGroup';
const RADIO_GROUP = 'RadioButtonGroup';
const LABEL = 'Label';
const UPLOAD = 'Upload';

interface FormInputsProps {
  formValues: formState;
  config: formConfig[];
  clickedNextButton?: boolean;
  getValidation?: (
    element: formConfig
  ) => { isValid: boolean; message: string }[];
  handleFormChange: (e: formControlsChangeEvent) => void;
  inputRefs: any;
}

const FormInputs: React.FC<FormInputsProps> = ({
  formValues,
  config,
  clickedNextButton,
  inputRefs,
  handleFormChange,
  getValidation,
}) => {
  const inputMap = (formConfig: formConfig) => {
    let error = {
      isValid: true,
      message: '',
    };

    if (getValidation && clickedNextButton) {
      const validation = getValidation(formConfig);
      error = {
        isValid: !validation.some(error => !error.isValid),
        message: validation.find(error => !error.isValid)?.message ?? '',
      };
    }

    if (
      formConfig.type !== BUTTON_GROUP &&
      formConfig.type !== DATE_TIME &&
      formConfig.type !== CHECKBOX &&
      formConfig.type !== RADIO_GROUP &&
      formConfig.type !== LABEL &&
      formConfig.type !== UPLOAD &&
      formConfig.textFieldProps
    ) {
      formConfig.textFieldProps.error = !error.isValid;
      formConfig.textFieldProps.helperText = error.message;
    }

    switch (formConfig.type) {
      case DROPDOWN:
        return (
          <Dropdown
            key={formConfig.key}
            value={formValues[formConfig.key]}
            onChange={handleFormChange}
            name={formConfig.key}
            options={formConfig.options}
            textFieldProps={formConfig.textFieldProps}
          />
        );
      case TEXT_INPUT:
        return (
          <TextInput
            key={formConfig.key}
            value={formValues[formConfig.key]}
            name={formConfig.key}
            onChange={handleFormChange}
            textFieldProps={formConfig.textFieldProps}
            mask={formConfig.mask}
            inputRefs={inputRefs}
            onlyNumber={formConfig.onlyNumber}
            nextInput={formConfig.nextInput}
          />
        );
      case BUTTON_GROUP:
        return (
          <ButtonGroup
            key={formConfig.key}
            value={formValues[formConfig.key]}
            onChange={handleFormChange}
            buttonList={formConfig.buttonList}
            style={formConfig.style}
            name={formConfig.name}
          />
        );
      case DATE_TIME:
        return (
          <DatePicker
            key={formConfig.key}
            name={formConfig.key}
            value={formValues[formConfig.key]}
            onChange={handleFormChange}
            error={!error.isValid}
            helperText={error.message}
          />
        );
      case CHECKBOX:
        return (
          <CustomCheckbox
            key={formConfig.key}
            value={formValues[formConfig.key]}
            name={formConfig.key}
            elements={formConfig.elements}
            onChange={handleFormChange}
            title={formConfig.title}
          />
        );
      case RADIO_GROUP:
        return (
          <RadioButtonsGroup
            key={formConfig.key}
            value={formValues[formConfig.key]}
            name={formConfig.key}
            address={formConfig.address}
            onChange={handleFormChange}
            addrForm={formConfig.addrForm}
            setAddrForm={formConfig.setAddrForm}
            editAddrForm={formConfig.editAddrForm}
            setEditAddrForm={formConfig.setEditAddrForm}
          />
        );
      case LABEL:
        return <Label key={formConfig.key} label={formConfig.label} />;
      case UPLOAD:
        return (
          <Upload
            key={formConfig.key}
            value={formValues[formConfig.key]}
            name={formConfig.key}
            onChange={handleFormChange}
          />
        );
      default:
        return null;
    }
  };

  const generateInputs = () => {
    return config.map(e => {
      return (
        <Grid key={e.key} item xs={e.size}>
          {inputMap(e)}
        </Grid>
      );
    });
  };

  return <>{generateInputs()}</>;
};

export default FormInputs;
