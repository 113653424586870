import {
  Box,
  Checkbox,
  CheckboxProps,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
} from '@mui/material';

export type CheckboxValue = {
  [key: string]: boolean | undefined;
};

interface CustomCheckboxProps extends Omit<CheckboxProps, 'onChange'> {
  value: CheckboxValue[];
  name: string;
  title: string;
  elements: string[];
  hideOverflow?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const CustomCheckbox: React.FC<CustomCheckboxProps> = ({
  value,
  name,
  title,
  elements,
  onChange,
  hideOverflow,
}) => {
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    onChange({
      target: {
        value: { ...value, [event.target.name]: checked },
        name,
      },
    } as any);
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          border: '1px solid #A5A5A5',
          borderRadius: 2,
        }}
      >
        <FormControl sx={{ m: 2 }} component='fieldset' variant='standard'>
          <FormLabel
            style={{
              color: '#000000',
              marginBottom: 20,
              fontSize: 15,
              marginRight: 'auto',
              marginLeft: 0,
            }}
            component='legend'
          >
            {title}
          </FormLabel>
          <FormGroup>
            {elements.map((element: string, index: number) => (
              <FormControlLabel
                key={index}
                style={{ color: '#444444' }}
                control={
                  <Checkbox
                    key={index}
                    checked={value ? (value[element as any] as any) : false}
                    onChange={handleChange}
                    style={{
                      transform: 'scale(1.3)',
                    }}
                    name={element}
                  />
                }
                label={element}
              />
            ))}
          </FormGroup>
        </FormControl>
      </Box>
    </>
  );
};

export default CustomCheckbox;
