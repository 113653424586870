import { useEffect, useState } from 'react';
import { FormTabConfig } from '../../components/MultiTabForm/MultiTabForm';
import { formState } from '../../components/useFormControls';
import { api } from '../../services/Api';
import { TypographyContainerFormTabs } from '../../components/MultiTabForm/styles';
import { isMobile } from 'react-device-detect';

export const categoryTabState: formState = {
  category: 0,
  subCategory: 0,
};

export const useCategoryTab = (formCurrentValues: formState[]) => {
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);

  const getCategoriesAndSubcategories = async () => {
    await api.get(`/forms/allCategoriesSubCategories/`).then(res => {
      if (res?.data?.formInfo?.status === 'success') {
        const categories = res?.data?.formInfo?.allForms
          ?.map((element: any) => ({
            key: element?.Expertise?.id,
            label: element?.Expertise?.categoryName,
            name: element?.Expertise?.categoryName,
          }))
          .filter(
            (value: any, index: any, self: any) =>
              index ===
              self.findIndex(
                (t: any) => t.place === value.place && t.name === value.name
              )
          );
        setCategories(categories);
        const subcategories = res?.data?.formInfo?.allForms?.map(
          (element: any) => ({
            parentLabel: element?.Expertise?.categoryName,
            key: element?.Subcategory?.id,
            label: element?.Subcategory?.subCategoryName,
            name: element?.Subcategory?.subCategoryName,
          })
        );
        setSubCategories(subcategories);
      }
    });
  };

  useEffect(() => {
    getCategoriesAndSubcategories();
  }, []);

  const getSubcategoriesButtonList = () => {
    if (
      subCategories.length &&
      categories.length &&
      formCurrentValues.length &&
      formCurrentValues[0]?.category
    )
      return subCategories.filter(
        (element: any) =>
          element.parentLabel ===
          (
            categories.find(
              (element: any) => element.key === formCurrentValues[0]?.category
            ) as any
          )?.label
      );
    return [];
  };

  const categoryTab: FormTabConfig = {
    formState: categoryTabState,
    mandatory: true,
    label: (
      <TypographyContainerFormTabs>
        Por favor, escolha a categoria:
      </TypographyContainerFormTabs>
    ),
    formConfig: [
      {
        key: 'category',
        name: 'category',
        type: 'ButtonGroup',
        size: 12,
        buttonList: categories,
        style: {
          width: '48%',
          height: isMobile ? 68 : 68,
          marginTop: isMobile ? 5 : 20,
          marginLeft: isMobile ? 5 : 8,
          boxShadow: 'none',
          border: '1px solid #D4D4D4',
        },
      },
      {
        key: 'subCategoryLabel',
        type: 'Label',
        size: 12,
        label: formCurrentValues[0]?.category ? 'Escolha a subcategoria:' : '',
      },
      {
        key: 'subCategory',
        name: 'subCategory',
        type: 'ButtonGroup',
        size: 12,
        buttonList: getSubcategoriesButtonList(),
        style: {
          width: '100%',
          height: 68,
          marginTop: 20,
          boxShadow: 'none',
          border: '1px solid #D4D4D4',
        },
      },
    ],
  };

  return { categoryTab } as const;
};
