import { useEffect, useRef, useState } from 'react';
import MultiTabForm from '../MultiTabForm/MultiTabForm';
import { formState } from '../useFormControls';
import { ThemeProvider } from '@emotion/react';
import { FormTheme } from '../../constants/theme';
import {
  categoryTabState,
  useCategoryTab,
} from '../../hooks/FormTabs/useCategoryTab';
import {
  serviceDateTabState,
  useServiceDateTab,
} from '../../hooks/FormTabs/useServiceDateTab';
import {
  identificationTabState,
  useIdentificationTab,
} from '../../hooks/FormTabs/useIdentificationTab';
import {
  userNotFoundTabState,
  useUserNotFoundTab,
} from '../../hooks/FormTabs/useUserNotFoundTab';
import {
  confirmationTabState,
  useConfirmationTab,
} from '../../hooks/FormTabs/useConfirmationTab';
import {
  userFoundTabState,
  useUserFoundTab,
} from '../../hooks/FormTabs/useUserFoundTab';
import { useFinalTab } from '../../hooks/FormTabs/useFinalTab';
import { useDetailTab } from '../../hooks/FormTabs/useDetailTab';

function MultiTabFormDiv() {
  const [user, setUser] = useState<any>();
  const inputRefs = useRef<any>({});

  const [detailTabState, setDetailTabState] = useState({});
  const [addrForm, setAddrForm] = useState({});
  const [editAddrForm, setEditAddrForm] = useState({});

  const formStates = [
    categoryTabState,
    detailTabState,
    serviceDateTabState,
    identificationTabState,
    userNotFoundTabState,
    confirmationTabState,
    {},
  ];

  const [formCurrentValues, setFormCurrentValues] =
    useState<formState[]>(formStates);
  const [sendTokenAgain, setSendTokenAgain] = useState(false);

  useEffect(() => {
    if (user?.exists)
      setFormCurrentValues((ps:any) => {
        ps[4] = userFoundTabState;
        return [...ps];
      });
    else
      setFormCurrentValues((ps:any) => {
        ps[4] = userNotFoundTabState;
        return [...ps];
      });
  }, [user]);

  const getPhone = () => {
    if (user?.exists) return user.client.phone;
    else if (formCurrentValues && formCurrentValues[4]?.phone)
      return formCurrentValues[4].phone.replace(/\D/g, '');

    return '';
  };

  const getClientId = () => {
    if (user?.client) return user.client.id;
    return '';
  };

  const { categoryTab } = useCategoryTab(formCurrentValues);

  const { serviceDateTab } = useServiceDateTab();
  const { identificationTab } = useIdentificationTab();
  const { userNotFoundTab } = useUserNotFoundTab(formCurrentValues);
  const { confirmationTab } = useConfirmationTab(
    getPhone(),
    getClientId(),
    sendTokenAgain,
    setSendTokenAgain,
    inputRefs
  );
  const { finalTab } = useFinalTab();
  const { userFoundTab } = useUserFoundTab(
    user,
    addrForm,
    setAddrForm,
    setEditAddrForm,
    editAddrForm
  );

  const { detailTab } = useDetailTab(
    formCurrentValues[0].subCategory,
    detailTabState,
    setDetailTabState
  );

  const formTabConfig = [
    categoryTab,
    detailTab,
    serviceDateTab,
    identificationTab,
    user?.exists ? userFoundTab : userNotFoundTab,
    confirmationTab,
    finalTab,
  ];

  return (
    <div style={{ marginBottom: 50 }}>
      <ThemeProvider theme={FormTheme}>
        <MultiTabForm
          formInitialValues={formStates as formState[]}
          formTabConfig={formTabConfig}
          setFormCurrentValues={setFormCurrentValues}
          user={user}
          setUser={setUser}
          addrForm={addrForm}
          editAddrForm={editAddrForm}
          setSendTokenAgain={setSendTokenAgain}
          inputRefs={inputRefs}
        />
      </ThemeProvider>
    </div>
  );
}

export default MultiTabFormDiv;
