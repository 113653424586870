import MaskedInput from 'react-text-mask';

export type Mask = Array<string | RegExp> | false;
interface TextInputMaskProps {
  inputRef: (ref: HTMLInputElement | null) => void;
  mask: Mask | ((value: string) => Mask);
}

const TextInputMask = ({ inputRef, mask, ...other }: TextInputMaskProps) => {
  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        if (inputRef) inputRef(ref ? ref.inputElement : null);
      }}
      mask={mask}
      placeholderChar={'\u2000'}
    />
  );
};

export default TextInputMask;
