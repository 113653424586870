import { styled } from '@mui/system';
import { colors } from '../theme/colors';

export const HeaderDiv = styled('div')`
  position: fixed;
  z-index: 10;
  background: white;
  width: 100vw;
  padding: 3vh 14.7%;
  box-sizing: border-box;
  transition: 0.5s ease;
  display: flex;

  @media only screen and (max-width: 1919px) {
    padding: 3vh 10%;
  }

  @media only screen and (max-width: 1100px) {
    padding: 3vh 2%;
  }

  div {
    &.logo {
      cursor: pointer;
      margin-left: 6.5%;
    }

    &.buttons {
      position: fixed;
      display: flex;
      justify-content: space-evenly;
      right: 5%;
      top: 3%;
      button {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  }

  &.mobile {
    z-index: 10;
    padding-left: 8.3%;
    padding-top: 2%;
    padding-bottom: 2%;
    width: 100vw;
    svg {
      margin-top: 2%;
    }
    div.buttons {
      display: flex;
      padding-left: 20%;

      Button {
        margin-left: 1%;
        margin-top: -5%;
        height: 120%;
      }
    }
  }
`;

export const Div = styled('div')`
  background-color: white;
  padding-top: 100px;

  &.no-padding {
    padding-top: 52px;
  }

  &.center {
    margin: auto;
    margin-top: 52px;
    width: 70vw;
    height: 60vh;
    padding-top: 0;
  }

  &.first {
    background-color: white;
    display: flex;
    justify-content: space-evenly;
    Button {
      margin-top: 48px;
      margin-bottom: 180px;
    }
  }

  &.divider {
    border: 1px solid ${colors.div.secondary};
    height: 0%;
    padding: 0;
    margin-left: -15%;
    width: 100vw;
    margin-top: -20%;
    margin-bottom: 12%;
  }

  &.text-mobile-margin {
    padding-left: 11%;
    margin-top: -10%;
    padding-right: 11%;
    padding-bottom: 11%;
    Button {
      margin-top: 11%;
      font-size: 14px;
    }
  }

  &.text-mobile {
    background-color: white;
    padding-left: 11%;
    padding-right: 11%;
    Button {
      margin-top: 11%;
      font-size: 14px;
      margin-bottom: 24%;
      margin-left: 6%;
      &.big {
        margin-left: auto;
        margin-right: auto;
        width: 100%;
      }
      &.small {
        margin-left: 20%;
        margin-bottom: 0;
      }
    }
    div.carousel {
      padding-bottom: 0;

      .steps {
        background: ${colors.div.secondary};
        padding: 10px 20px;
        border-radius: 4px;
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14),
          0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
      }
      div {
        margin-top: 2%;
        text-align: center;
        h1 {
          color: ${colors.text.primary};
          font-weight: 500;
          font-size: 20px;
          line-height: 24px;
        }
        h2 {
          margin-top: 10%;
          font-size: 14px;
          line-height: 20px;
          font-weight: 400;
          color: ${colors.text.primary};
        }
      }
    }
  }

  &.icon-right {
    margin-top: 50px;
    padding-top: 0;
  }

  &.icon-right-mobile {
    margin: 5% auto -20%;
    width: 70%;
    padding-top: 0;

    svg {
      max-width: 100%;
    }
  }

  &.first-mobile {
    background-color: white;
    display: flex;
    flex-flow: column;
  }

  &.icon-top-right {
    margin-bottom: 74px;
    padding-top: 0;
  }

  &.icon-left {
    margin-top: 15px;
    padding-top: 0;
    margin-bottom: 98px;
  }

  &.margin-left {
    padding-top: 0;
    margin-top: auto;
    margin-bottom: auto;
    background-color: ${colors.div.secondary};
  }

  &.gray {
    background-color: ${colors.div.secondary};
    text-align: center;
  }

  &.mobile-gray {
    padding-top: 10%;
    padding-left: 2%;
    padding-right: 2%;
    background-color: ${colors.div.secondary};
    text-align: center;
  }

  &.mobile-less-top {
    padding-top: 0;
  }

  &.gray-flex {
    background-color: ${colors.div.secondary};
    text-align: center;
    padding-top: 0;
    display: flex;
    justify-content: space-evenly;

    svg.first-icon {
      margin-left: -4%;
    }
  }

  &.gray-flex-right {
    background-color: ${colors.div.secondary};
    text-align: center;
    padding-top: 0;
    display: flex;
    justify-content: left;
  }

  &.gray-flex-mobile {
    background-color: ${colors.div.secondary};
    text-align: center;
    padding-top: 0;
    padding-left: 2%;
    padding-right: 2%;
    display: flex;
    flex-flow: column;
    justify-content: space-evenly;
  }

  &.text-center {
    margin-bottom: 2px;
    text-align: center;
    Button {
      margin-top: 48px;
      margin-bottom: 80px;
    }
  }

  &.flex {
    display: flex;
    justify-content: space-evenly;
  }

  &.only-flex {
    display: flex;
    justify-content: space-evenly;
    Button {
      margin-top: 38px;
    }
  }
`;

export const Content = styled('div')`
  background-color: ${colors.div.secondary};
`;

export const DivTable = styled('div')`
  display: flex;
  margin-top: 84px;
  margin-bottom: 84px;
  justify-content: space-evenly;

  &.mobile-table-row {
    display: flex;
    flex-flow: column;
    justify-content: center;
    margin-top: -1.5%;
    margin-bottom: 1%;
    div {
      margin-top: 4%;
      width: 90%;
      margin-left: -20%;
      svg {
        width: 100%;
      }
      &.margin-left {
        margin-right: -30%;
      }
      &.firstArrow {
        margin-left: -3%;
        margin-top: -5%;
      }
      &.secondArrow {
        margin-left: -3%;
        margin-top: -10%;
      }
    }
  }

  &.table-row {
    display: table-row;
    margin-top: 0px;
    margin-bottom: 34px;
    div {
      margin-left: 0;
      margin-top: 10px;
      align-self: left;
    }
  }

  div {
    text-align: center;
    h1 {
      color: ${colors.text.primary};
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 4px;
    }

    h2 {
      margin-top: 20px;
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      color: ${colors.text.primary};
    }

    &.mobile-flex {
      background-color: ${colors.div.secondary};
      text-align: center;
      display: flex;
      margin-left: 5%;
      margin-right: 50%;
      padding-top: 0;
    }

    &.firstArrow {
      margin-left: -80%;
    }
    &.secondArrow {
      margin-left: -80%;
    }
  }
`;

export const CardTable = styled('div')`
  display: flex;
  margin-top: 84px;
  margin-bottom: 84px;
  justify-content: center;

  &.mobile-table-row {
    display: flex;
    flex-flow: column;
    justify-content: center;
    margin-top: -1.5%;
    margin-bottom: 1%;
    div {
      margin-top: 4%;
      width: 90%;
      margin-left: -20%;
      svg {
        width: 100%;
      }
      &.margin-left {
        margin-right: -30%;
      }
      &.firstArrow {
        margin-left: -3%;
        margin-top: -5%;
      }
      &.secondArrow {
        margin-left: -3%;
        margin-top: -10%;
      }
    }
  }

  &.table-row {
    display: table-row;
    margin-top: 0px;
    margin-bottom: 34px;
    div {
      margin-left: 0;
      margin-top: 10px;
      align-self: left;
    }
  }

  div {
    text-align: center;
    padding: 1%;
    background: #ffffff;
    margin: 1%;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12),
      0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    max-width: 300px;

    h1 {
      text-align: left;
      color: ${colors.text.primary};
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      margin-top: 20px;
      margin-bottom: 4px;
    }

    h2 {
      text-align: left;
      margin-top: 20px;
      margin-bottom: 4px;
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      color: ${colors.text.primary};
    }

    &.mobile-flex {
      background-color: ${colors.div.secondary};
      text-align: center;
      display: flex;
      margin-left: 5%;
      margin-right: 50%;
      padding-top: 0;
    }

    &.firstArrow {
      margin-left: -80%;
    }
    &.secondArrow {
      margin-left: -80%;
    }
  }
`;

export const Text = styled('div')`
  &.left {
    text-align: left;
    margin-left: 0px;
    h1 {
      font-size: 34px;
      font-weight: 400;
      line-height: 36px;
      letter-spacing: 0px;
      color: ${colors.text.secondary};
    }

    h3 {
      margin-top: 24px;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.15px;
      color: ${colors.text.secondary};

      &.no-margin-top {
        margin-top: 0;
      }
    }
  }

  &.margin-left {
    text-align: left;
  }

  &.center-mobile {
    text-align: center;
    padding: 5%;
  }

  &.center-mobile-margin {
    margin-top: -10%;
    text-align: center;
  }

  h1 {
    font-size: 34px;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0px;
    color: ${colors.text.secondary};
  }

  h3 {
    margin-top: 24px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: ${colors.text.secondary};

    &.no-margin-top-margin-left {
      margin-top: 0;
    }
    &.no-margin-top-margin-left-second {
      margin-top: 0;
      margin-left: 5%;
      margin-right: -5%;
    }
    &.no-margin-top {
      margin-top: 0;
    }
    a {
      color: inherit;
    }
  }

  h2 {
    margin-top: 24px;
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
  }

  a {
    color: ${colors.text.emphasys};
  }

  &.white {
    h1,
    text,
    h3 {
      color: ${colors.div.secondary};
    }
  }

  &.titleBlue {
    h1 {
      color: ${colors.text.emphasys};
    }
  }
`;

export const Emphasys = styled('text')`
  color: ${colors.text.emphasys};
`;

export const Footer = styled('text')`
  background-color: ${colors.div.secondary};
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;

  &.mobile {
    display: flex;
    flex-flow: column;
    align-content: center;
    padding-left: 0;
    padding-right: 0;
    h1 {
      margin: 0;
      margin-top: 24px;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.4px;
    }
    a {
      margin: 0;
      margin-top: 1%;
      margin-bottom: 5%;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.4px;
      text-decoration-line: underline;
    }
  }

  h1 {
    color: ${colors.text.primary};
    margin-top: 24px;
    margin-bottom: 24px;
    margin-left: 10%;
    margin-right: auto;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
  }

  a {
    color: ${colors.text.primary};
    margin-top: 24px;
    margin-bottom: 24px;
    margin-left: auto;
    margin-right: 10%;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    text-decoration-line: underline;
    cursor: pointer;
  }
`;
