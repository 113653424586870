import * as yup from 'yup';
import { formState } from '../components/useFormControls';

export const validateMandatoryDropdown = (
  errorMessage: string,
  key: string
) => ({
  validateSync: (formValues: formState) => {
    const value = formValues[key];
    if (!value || typeof value === 'string') {
      throw new Error(errorMessage);
    }

    const dropdownShape = yup.object({
      id: yup.number().required(errorMessage),
    });

    try {
      dropdownShape.validateSync(value);
    } catch (e: any) {
      throw new Error(errorMessage);
    }
  },
});

export const validateCEP = (cep?: string) => {
  if (!cep) return false;
  if (!cep.replace(/\D/g, '')) return false;

  const cepRegex = /[0-9]{5}-[0-9]{3}/;
  return cepRegex.test(cep);
};

export const validateCNPJ = (cnpj?: string) => {
  if (!cnpj) return false;

  const BLACKLIST = [
    '00000000000000',
    '11111111111111',
    '22222222222222',
    '33333333333333',
    '44444444444444',
    '55555555555555',
    '66666666666666',
    '77777777777777',
    '88888888888888',
    '99999999999999',
  ];

  const STRICT_STRIP_REGEX: RegExp = /[-\\/.]/g;
  const LOOSE_STRIP_REGEX: RegExp = /[^\d]/g;

  const verifierDigit = (digits: string): number => {
    let index: number = 2;
    const reverse: number[] = digits.split('').reduce((buffer: any, number) => {
      return [parseInt(number, 10)].concat(buffer);
    }, []);

    const sum = reverse.reduce((buffer, number) => {
      buffer += number * index;
      index = index === 9 ? 2 : index + 1;
      return buffer;
    }, 0);

    const mod = sum % 11;
    return mod < 2 ? 0 : 11 - mod;
  };

  const strip = (number: string, strict?: boolean) => {
    const regex = strict ? STRICT_STRIP_REGEX : LOOSE_STRIP_REGEX;
    return (number || '').replace(regex, '');
  };

  const isValid = (number: string, strict: boolean = true) => {
    const stripped = strip(number, strict);

    // CNPJ must be defined
    if (!stripped) {
      return false;
    }

    // CNPJ must have 14 chars
    if (stripped.length !== 14) {
      return false;
    }

    // CNPJ can't be blacklisted
    if (BLACKLIST.includes(stripped)) {
      return false;
    }

    let numbers = stripped.substr(0, 12);
    numbers += verifierDigit(numbers);
    numbers += verifierDigit(numbers);

    return numbers.substr(-2) === stripped.substr(-2);
  };

  return isValid(cnpj);
};

export const validateCPF = (cpf?: string) => {
  if (!cpf) return false;
  if (!cpf.replace(/\D/g, '')) return false;

  const BLACKLIST: Array<string> = [
    '00000000000',
    '11111111111',
    '22222222222',
    '33333333333',
    '44444444444',
    '55555555555',
    '66666666666',
    '77777777777',
    '88888888888',
    '99999999999',
    '12345678909',
  ];

  const STRICT_STRIP_REGEX: RegExp = /[.-]/g;
  const LOOSE_STRIP_REGEX: RegExp = /[^\d]/g;

  const verifierDigit = (digits: string): number => {
    const numbers: Array<number> = digits.split('').map(number => {
      return parseInt(number, 10);
    });

    const modulus: number = numbers.length + 1;
    const multiplied: Array<number> = numbers.map(
      (number, index) => number * (modulus - index)
    );
    const mod: number =
      multiplied.reduce((buffer, number) => buffer + number) % 11;

    return mod < 2 ? 0 : 11 - mod;
  };

  const strip = (number: string, strict?: boolean): string => {
    const regex: RegExp = strict ? STRICT_STRIP_REGEX : LOOSE_STRIP_REGEX;
    return (number || '').replace(regex, '');
  };

  const isValid = (number: string, strict?: boolean): boolean => {
    const stripped: string = strip(number, strict);

    // CPF must be defined
    if (!stripped) {
      return false;
    }

    // CPF must have 11 chars
    if (stripped.length !== 11) {
      return false;
    }

    // CPF can't be blacklisted
    if (BLACKLIST.includes(stripped)) {
      return false;
    }

    let numbers: string = stripped.substr(0, 9);
    numbers += verifierDigit(numbers);
    numbers += verifierDigit(numbers);

    return numbers.substr(-2) === stripped.substr(-2);
  };
  return isValid(cpf);
};

export const validateEmail = (email?: string) => {
  if (!email) return false;
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (email.toLowerCase().match(emailRegex)) return true;

  return false;
};

export const validatePhone = (phone?: string) => {
  if (!phone) return false;
  if (!phone.replace(/\D/g, '')) return false;

  // const phoneRegex = /([0-9]{2})[\s][0-9]{1}[\s][0-9]{4}-[0-9]{4}/;
  // return phoneRegex.test(phone);

  return phone.replace(/\D/g, '').length === 11;
};
