import axios from 'axios';
import { baseUrl } from '../constants/Routes/routes';

export const _api = axios.create({
  baseURL: baseUrl,
  headers: {
    'api-authorization': `Bearer ${localStorage.getItem('accessToken')}`,
    authorization: 'Bearer 80158411-9BD7-411B-BAEF-6C3D13539E17',
  },
});

export const api = {
  put: async (route: string, data: Object) => {
    return await _api.put(route, data);
  },

  post: async (route: string, data: Object) => {
    return await _api.post(route, data);
  },

  get: async (route: string) => {
    return await _api.get(route);
  },
};
