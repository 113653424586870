import * as React from 'react';
import Radio from '@mui/material/Radio';
import { Typography } from '@mui/material';
import { SaveButton, StyledTextField } from './styles';
import { Edit } from '@mui/icons-material';
import { getFullAddress } from '../../../utils/cep';
import { formState } from '../../useFormControls';

interface RadioButtonGroupProps {
  value: any;
  name: string;
  address: any[];
  onChange: any;
  addrForm: formState;
  setAddrForm: any;
  editAddrForm: any;
  setEditAddrForm: any;
}

const RadioButtonsGroup: React.FC<RadioButtonGroupProps> = ({
  value,
  name,
  address,
  onChange,
  addrForm,
  setAddrForm,
  editAddrForm,
  setEditAddrForm,
}) => {
  const [editAddress, setEditAddress] = React.useState();
  const [fullAddress, setFullAddress] = React.useState<any>({});

  React.useEffect(() => {
    if (value !== 'newAddress') setAddrForm({});
  }, [setAddrForm, value]);

  const createTargetEvent = (value: string) =>
    ({
      target: {
        value,
        name,
      },
    } as any);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    if (!!e.target.value) {
      const event = createTargetEvent(e.target.value);
      onChange(event);
    }
  };

  React.useEffect(() => {
    if (fullAddress) {
      addrForm.district = fullAddress.bairro;
      addrForm.city = fullAddress.localidade;
      addrForm.addr = fullAddress.logradouro;
      addrForm.province = fullAddress.uf;
      setAddrForm(addrForm);
    }
  }, [addrForm, fullAddress, setAddrForm]);

  React.useEffect(() => {
    if (addrForm && addrForm['zipCode'] && addrForm['zipCode'].length === 8) {
      const fetchCep = async () => {
        setFullAddress(await getFullAddress(addrForm['zipCode']));
      };

      fetchCep();
    }
  }, [addrForm, setAddrForm]);

  const handleInputChange = (e: any) => {
    setAddrForm((previousState: any) => ({
      ...previousState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleCepChange = (e: any) => {
    setAddrForm((previousState: any) => ({
      ...previousState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleEditChange = (e: any) => {
    setEditAddrForm((previousState: any) => ({
      ...previousState,
      [e.target.name]: e.target.value,
    }));
  };

  const onEditAddress = (id: any) => {
    address.forEach(e => {
      if (e.id !== id) {
        return;
      }

      setEditAddrForm({ ...e });
      setEditAddress(id);
    });
  };

  const saveAddress = (index: number) => {
    address[index] = editAddrForm;
  };

  return (
    <>
      {address &&
        address.map((a, index) => (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              border: '1px solid #0000001F',
              padding: 10,
              borderRadius: 5,
              marginBottom: 20,
            }}
          >
            <Radio
              key={index}
              style={{ marginBottom: 'auto' }}
              checked={value ? value === a.id : address[0].id === a.id}
              onChange={handleChange}
              value={a.id}
            />
            <div
              style={{
                display: editAddress === a.id ? 'inline' : 'flex',
              }}
            >
              {editAddress === a.id ? (
                <>
                  <StyledTextField
                    key='zipCode'
                    name='zipCode'
                    label='CEP'
                    type='number'
                    inputProps={{ min: 0 }}
                    value={editAddrForm['zipCode']}
                    onChange={handleEditChange}
                  />
                  <StyledTextField
                    key='addr'
                    name='addr'
                    label='Endereço'
                    value={editAddrForm['addr']}
                    onChange={handleEditChange}
                  />
                  <StyledTextField
                    key='addrNumber'
                    name='addrNumber'
                    type='number'
                    inputProps={{ min: 0 }}
                    label='Número'
                    value={editAddrForm['addrNumber']}
                    onChange={handleEditChange}
                  />
                  <StyledTextField
                    key='district'
                    name='district'
                    label='Bairro'
                    value={editAddrForm['district']}
                    onChange={handleEditChange}
                  />
                  <StyledTextField
                    key='extra'
                    name='extra'
                    label='Complemento'
                    value={editAddrForm['cep']}
                    onChange={handleEditChange}
                  />
                  <StyledTextField
                    key='city'
                    name='city'
                    label='Cidade'
                    value={editAddrForm['city']}
                    onChange={handleEditChange}
                  />
                  <StyledTextField
                    key='province'
                    name='province'
                    label='UF'
                    value={editAddrForm['province']}
                    onChange={handleEditChange}
                  />
                  <SaveButton
                    style={{ width: 'auto' }}
                    onClick={() => {
                      setEditAddress(undefined);
                    }}
                  >
                    Cancelar
                  </SaveButton>
                  <SaveButton
                    style={{ marginLeft: 5 }}
                    variant='contained'
                    onClick={() => {
                      saveAddress(index);
                      setEditAddress(undefined);
                    }}
                  >
                    Salvar
                  </SaveButton>
                </>
              ) : (
                <>
                  <Typography maxWidth={'80%'}>
                    {a.addr +
                      ', ' +
                      a.addrNumber +
                      ', ' +
                      a.district +
                      ' - ' +
                      a.city +
                      '/' +
                      a.province +
                      '\n' +
                      a.zipCode}
                  </Typography>
                  <Edit
                    style={{
                      marginLeft: 'auto',
                      marginTop: '1.5%',
                      marginRight: 0,
                    }}
                    onClick={() => {
                      onEditAddress(a.id);
                    }}
                  />
                </>
              )}
            </div>
          </div>
        ))}
      <div
        style={{
          border: '1px solid #0000001F',
          padding: 10,
          borderRadius: 5,
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Radio
            key='newAddress'
            checked={value === 'newAddress'}
            onChange={handleChange}
            value={'newAddress'}
          />
          <Typography>Adicionar novo endereço</Typography>
        </div>
        {value === 'newAddress' && (
          <div style={{ marginTop: 20 }}>
            <StyledTextField
              key='zipCode'
              name='zipCode'
              label='CEP'
              type='number'
              inputProps={{ min: 0 }}
              value={addrForm.zipCode || ''}
              onChange={handleInputChange}
              onBlur={handleCepChange}
            />
            <StyledTextField
              key='addr'
              name='addr'
              label='Endereço'
              value={addrForm.addr || ''}
              onChange={handleInputChange}
            />
            <StyledTextField
              key='addrNumber'
              name='addrNumber'
              type='number'
              inputProps={{ min: 0 }}
              label='Número'
              value={addrForm.addrNumber || ''}
              onChange={handleInputChange}
            />
            <StyledTextField
              key='district'
              name='district'
              label='Bairro'
              value={addrForm.district || ''}
              onChange={handleInputChange}
            />
            <StyledTextField
              key='extra'
              name='extra'
              label='Complemento'
              value={addrForm.extra || ''}
              onChange={handleInputChange}
            />
            <StyledTextField
              key='city'
              name='city'
              label='Cidade'
              value={addrForm.city || ''}
              onChange={handleInputChange}
            />
            <StyledTextField
              key='province'
              name='province'
              label='UF'
              value={addrForm.province || ''}
              onChange={handleInputChange}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default RadioButtonsGroup;
