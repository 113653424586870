import axios from 'axios';

export const getFullAddress = async (cep?: string) => {
  let response = {};
  if (cep && cep.replace(/\D/g, '').length === 8)
    await axios
      .get(`https://viacep.com.br/ws/${cep.replace(/\D/g, '')}/json/`)
      .then(res => {
        response = res.data;
      })
      .catch(e => {
        console.log(e);
      });
  return response;
};
