/* eslint-disable jsx-a11y/anchor-is-valid */
import { scroller } from 'react-scroll';
import { S, icons } from './constants/styles';
import { Theme } from './constants/theme';
import { ThemeProvider } from '@mui/system';
import { BrowserView, MobileOnlyView, isMobile } from 'react-device-detect';
import Carousel from './components/carousel';

import Button from '@mui/material/Button';
import MultiTabFormDiv from './components/MultiTabFormDiv/MultiTabFormDiv';
import { useState } from 'react';
import SimpleDialog from './components/SImpleDialog/SimpleDialog';
import { Typography } from '@mui/material';
import { clientRoute } from './constants/Routes/routes';

function App() {
  const googleForm =
    'https://docs.google.com/forms/d/e/1FAIpQLScC1LsqD8idS5DQjXDYheCovCuTBm5DDZsGpZf5vQiDFxXL9w/viewform';
  const whatsAppUrl = 'https://api.whatsapp.com/send?phone=5548992049166';
  const loginUrl = clientRoute;
  const [openTermsOfUse, setOpenTermsOfUse] = useState(false);

  const workTypes = [
    <div>
      <icons.electric />
      <h1>Elétrica</h1>
      <h2>Eletricista</h2>
      <h2>Instalação de Tomadas</h2>
      <h2>Instalação de ventilador de teto</h2>
    </div>,
    <div>
      <icons.paint />
      <h1>Pintura</h1>
      <h2>Pintura interna</h2>
      <h2>Pintura externa</h2>
      <h2>Pintura de portão e grade</h2>
    </div>,
    <div>
      <icons.hydraulics />
      <h1>Hidráulica</h1>
      <h2>Encanador</h2>
      <h2>Instalação de máquina de lavar</h2>
      <h2>Instalação de torneiras</h2>
    </div>,
    <div>
      <icons.cooling />
      <h1>Climatização</h1>
      <h2>Instalação de ar condicionado</h2>
      <h2>Limpeza de ar condicionado</h2>
    </div>,
  ];

  const howWorkSteps = [
    <div className='steps'>
      <icons.step_one />
      <h1>Precisa de instalação ou reparo?</h1>
      <h2>
        Primeiro, você escolhe a categoria e o tipo de serviço que você precisa
        e preenche um formulário detalhando o seu problema.
      </h2>
    </div>,
    <div className='steps'>
      <icons.step_two />
      <h1>Profissionais parceiros estão à sua disposição!</h1>
      <h2>
        Em 24 horas você receberá até 3 orçamentos diretamente no seu WhatsApp.
        Mas fique de olho, alguns profissionais podem entrar em contato para
        agendar uma visita técnica.
      </h2>
    </div>,
    <div className='steps'>
      <icons.step_three />
      <h1>Compare os orçamentos e escolha o melhor!</h1>
      <h2>
        Você pode entrar em contato com o profissional para negociar valores e
        combinar a melhor data para a realização do serviço.
      </h2>
    </div>,
    <div className='steps'>
      <icons.step_four />
      <h1>Faltou material? A gente resolve rapidinho!</h1>
      <h2>
        Todo orçamento gera um pedido de compra de materiais que pode ser
        realizado com apenas um clique. Receba seu pedido em até 3 horas no
        conforto da sua casa.
      </h2>
    </div>,
  ];

  const executeScroll = (id: string, offset?: number) => {
    scroller.scrollTo(id, {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart',
      offset: offset,
    });
  };
  return (
    <ThemeProvider theme={Theme}>
      <S.Content>
        <S.HeaderDiv className={isMobile ? 'mobile' : ''}>
          <div className='logo' onClick={() => executeScroll('topo')}>
            <icons.logo />
          </div>
          <div className='buttons'>
            <BrowserView>
              <Button
                variant='text'
                onClick={() => executeScroll('solicitacao', 110)}
              >
                {' '}
                SOLICITAR ORÇAMENTO{' '}
              </Button>
              <Button
                variant='text'
                onClick={() => executeScroll('howWorks', -50)}
              >
                {' '}
                COMO FUNCIONA?{' '}
              </Button>
              <Button
                variant='text'
                onClick={() => executeScroll('services', -100)}
              >
                {' '}
                SERVIÇOS{' '}
              </Button>
              <Button
                variant='outlined'
                color='secondary'
                onClick={() => window.open(loginUrl)}
              >
                {' '}
                ENTRAR{' '}
              </Button>
            </BrowserView>
            <MobileOnlyView>
              <Button
                variant='outlined'
                color='secondary'
                onClick={() => window.open(loginUrl)}
              >
                {' '}
                ENTRAR{' '}
              </Button>
            </MobileOnlyView>
          </div>
        </S.HeaderDiv>

        <S.Div id='topo' className={isMobile ? 'first-mobile' : 'first'}>
          <MobileOnlyView>
            <S.Div className='icon-right-mobile'>
              <icons.professional />
            </S.Div>
          </MobileOnlyView>
          <S.Div className={isMobile ? 'text-mobile' : ''}>
            <S.Text className={isMobile ? 'center-mobile' : ''}>
              <h1>
                Precisando de{' '}
                <S.Emphasys>
                  instalação,
                  <br /> conserto ou reforma
                </S.Emphasys>
                ?
              </h1>
              <h3>
                Nós conectamos você a profissionais qualificados de forma
                <br /> rápida e segura. Detalhe seu problema, nós buscamos a
                <br /> solução.
              </h3>
            </S.Text>
            <ThemeProvider theme={Theme}>
              <Button
                onClick={() => executeScroll('solicitacao', 110)}
                variant='contained'
                color='secondary'
                className={isMobile ? 'big' : ''}
              >
                Conhecer o Zapti!
              </Button>
            </ThemeProvider>
          </S.Div>
          <BrowserView>
            <S.Div className='icon-right'>
              <icons.professional />
            </S.Div>
          </BrowserView>
        </S.Div>

        <S.Div
          id='howWorks'
          className={isMobile ? 'text-mobile' : 'text-center'}
          style={{ backgroundColor: '#0446F5' }}
        >
          <S.Text className={`${isMobile ? 'center-mobile-margin' : ''} white`}>
            <h1>
              Utilizar o <S.Emphasys>ZAPTI!</S.Emphasys> é muito fácil
            </h1>
            <h3>
              Queremos descomplicar a forma que você resolve suas manutenções da
              casa. <br />A gente te ensina aqui como funciona a nossa
              plataforma.
            </h3>
          </S.Text>
          <BrowserView>
            <S.CardTable>{howWorkSteps}</S.CardTable>
          </BrowserView>

          <MobileOnlyView>
            <div className='carousel bgWhite'>
              <Carousel>{howWorkSteps}</Carousel>
            </div>
          </MobileOnlyView>

          <ThemeProvider theme={Theme}>
            <Button
              onClick={() => executeScroll('solicitacao')}
              variant='contained'
              color='primary'
              className={isMobile ? 'big' : ''}
            >
              SOLICITAR UM ORÇAMENTO
            </Button>
          </ThemeProvider>
        </S.Div>

        <S.Div className={isMobile ? 'mobile-gray' : 'gray'} id='solicitacao'>
          <S.Text className={`${isMobile ? 'center-mobile' : ''} titleBlue`}>
            <h1>
              Envie seu <strong>pedido de orçamento</strong>
            </h1>
          </S.Text>
          <S.Text>
            <h3>
              Não é da <b>Grande Florianópolis / SC</b>?{' '}
              <a href={googleForm} target='_blank' rel='noreferrer'>
                Clique aqui
              </a>{' '}
              e nos ajude a<br />
              expandir nossos serviços para outras capitais e regiões.
            </h3>
          </S.Text>
          <MultiTabFormDiv />
        </S.Div>

        <S.Div
          id='services'
          style={{ paddingBottom: '10%' }}
          className={isMobile ? 'text-mobile' : 'text-center'}
        >
          <S.Text className={isMobile ? 'center-mobile-margin' : ''}>
            <h1>
              Temos o <S.Emphasys>serviço</S.Emphasys> que você precisa
            </h1>
            <h3>
              Encontre profissionais qualificados dentro das nossas categorias
              de serviços.
            </h3>
          </S.Text>
          <BrowserView>
            <S.DivTable>{workTypes}</S.DivTable>
          </BrowserView>

          <MobileOnlyView>
            <div className='carousel'>
              <Carousel>{workTypes}</Carousel>
            </div>
          </MobileOnlyView>

          <S.Text className={isMobile ? 'center-mobile' : ''}>
            <h3>
              Estamos presente na <b>Grande Florianópolis / SC</b>, mas em breve{' '}
              <br /> expandiremos para outras capitais e regiões.
            </h3>
          </S.Text>

          <ThemeProvider theme={Theme}>
            <Button
              variant='contained'
              color='secondary'
              className={isMobile ? 'small' : ''}
              onClick={() => executeScroll('howWorks', -50)}
            >
              VEJA COMO FUNCIONA
            </Button>
          </ThemeProvider>
        </S.Div>

        <S.Div className={isMobile ? '' : 'flex'}>
          <BrowserView>
            <S.Div className='icon-left'>
              <icons.professionalNetwork />
            </S.Div>
          </BrowserView>
          <S.Div className={isMobile ? 'text-mobile-margin' : ''}>
            <MobileOnlyView>
              <S.Div className='divider' />
            </MobileOnlyView>
            <S.Text className={isMobile ? 'center-mobile' : ''}>
              <h1>
                Rede de{' '}
                <S.Emphasys>
                  profissionais
                  <br />
                </S.Emphasys>{' '}
                parceiros da Cassol
              </h1>
              <h3>
                Com mais de 60 anos transformando lares e entregando soluções
                únicas, a<br /> Cassol possui uma rede sólida e confiável de
                fornecedores e profissionais
                <br /> de serviços.
              </h3>
              <h2>
                <S.Emphasys>+500</S.Emphasys>
              </h2>
              <h3 className='no-margin-top'>Profissionais cadastrados</h3>
            </S.Text>
          </S.Div>
        </S.Div>

        <S.Div className={isMobile ? 'gray-flex-mobile' : 'gray-flex'}>
          <S.Div className='margin-left'>
            <S.Text
              style={{ marginTop: isMobile ? '24px' : 0 }}
              className={isMobile ? 'center-mobile' : 'left'}
            >
              <h1>
                <S.Emphasys>Por que confiar</S.Emphasys> na nossa rede <br />
                de profissionais
              </h1>
              <h3>
                Todos os profissionais parceiros da plataforma passam por um
                processo
                <br />
                bastante rigoroso de avaliação e verificação. Nosso objetivo é
                garantir uma
                <br />
                experiência incrível resolvendo o seu problema, pode confiar!
              </h3>
            </S.Text>
          </S.Div>
          <S.DivTable>
            <S.DivTable className={isMobile ? 'mobile-table-row' : 'table-row'}>
              <div>
                <S.Div className={isMobile ? 'mobile-flex' : 'gray-flex'}>
                  <icons.presentation className='first-icon' />
                  <S.Text className='margin-left'>
                    <h3 className='no-margin-top-margin-left'>
                      Apresentação de <S.Emphasys>documentos</S.Emphasys>
                      <br /> e checagem de{' '}
                      <S.Emphasys>antecedentes criminais</S.Emphasys>.
                    </h3>
                  </S.Text>
                </S.Div>
                <div className='firstArrow'>
                  <icons.arrow />
                </div>
              </div>
              <div>
                <S.Div className={isMobile ? 'mobile-flex' : 'gray-flex'}>
                  <icons.group />
                  <S.Text className='margin-left'>
                    <h3 className='no-margin-top-margin-left-second'>
                      <S.Emphasys>Referências profissionais</S.Emphasys> para
                      certificarmos <br />
                      sobre a qualidade dos serviços prestados.
                    </h3>
                  </S.Text>
                </S.Div>
                <div className='secondArrow'>
                  <icons.arrow />
                </div>
              </div>
              <div>
                <S.Div className={isMobile ? 'mobile-flex' : 'gray-flex'}>
                  <icons.monitoring />
                  <S.Text className='margin-left'>
                    <h3 className='no-margin-top-margin-left-second'>
                      <S.Emphasys>Monitoramos todo processo</S.Emphasys> do seu
                      pedido a<br /> realização do serviço.
                    </h3>
                  </S.Text>
                </S.Div>
              </div>
            </S.DivTable>
          </S.DivTable>
        </S.Div>

        <S.Div className={isMobile ? 'mobile-less-top' : 'only-flex'}>
          <S.Div className={isMobile ? 'text-mobile' : 'no-padding'}>
            <S.Text className={isMobile ? 'center-mobile' : ''}>
              <h1>
                {' '}
                Se tiver qualquer{' '}
                <S.Emphasys>
                  dificuldade ou
                  <br /> dúvida
                </S.Emphasys>
                , estamos aqui!
              </h1>
              <h3>
                {' '}
                Para eventuais dúvidas sobre a plataforma, contate nossos
                consultores.
                <br />
                Nosso time estará sempre disponível para auxiliar você.{' '}
              </h3>
            </S.Text>
            <ThemeProvider theme={Theme}>
              <Button
                variant='contained'
                color='secondary'
                onClick={() => window.open(whatsAppUrl)}
              >
                FALAR COM nossos consultores
              </Button>
            </ThemeProvider>
          </S.Div>
          <BrowserView>
            <S.Div className='icon-top-right'>
              <icons.client />
            </S.Div>
          </BrowserView>
        </S.Div>

        <S.Footer className={isMobile ? 'mobile' : 'gray-flex'}>
          <h1>© 2022 ZAPTI! - Todos os direitos reservados</h1>
          {/* inputRefs */}
        </S.Footer>
      </S.Content>
      {openTermsOfUse && (
        <SimpleDialog
          open={openTermsOfUse}
          setOpen={setOpenTermsOfUse}
          dialogTitle={'Termos de Uso'}
          dialogContent={
            <>
              <Typography>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Curabitur at eros eu quam vulputate porttitor non sed nunc. Duis
                vehicula consectetur sem, pellentesque porta ipsum tristique at.
                Donec id purus est. Donec est orci, ornare eu mattis semper,
                ullamcorper ac mi. Maecenas finibus tortor eget metus tincidunt,
                nec gravida nunc aliquet. Sed tincidunt facilisis purus eget
                rhoncus. Vestibulum sit amet felis magna. Sed non dolor eu nunc
                luctus vulputate. Cras congue, diam quis sodales fringilla,
                magna mi sollicitudin nisi, ac lobortis felis mauris non elit.
                Donec finibus sit amet dolor vel sodales. Maecenas odio urna,
                dictum non tortor eu, faucibus gravida purus.
              </Typography>
              <Typography style={{ marginTop: 30 }}>
                Quisque ornare posuere sapien ut pretium. Mauris sodales nisl
                quis nunc porttitor, lobortis tincidunt mauris sollicitudin.
                Aliquam nisl purus, convallis iaculis sapien ac, vehicula
                molestie tortor. Aliquam vitae lacus nec eros semper feugiat.
                Nulla porta arcu quis rhoncus congue. Pellentesque a dui varius,
                egestas ipsum id, accumsan augue. Curabitur finibus malesuada
                sapien, eu bibendum sapien ornare eu. Proin pellentesque, arcu
                vitae convallis ornare, dui tortor luctus augue, rutrum sagittis
                dui tellus quis est.
              </Typography>
              <Typography style={{ marginTop: 30 }}>
                In sagittis venenatis odio et dictum. Sed nec vehicula enim. In
                eu libero magna. Cras sed augue metus. Mauris at pellentesque
                lectus. Aliquam ornare odio convallis urna aliquam tempor.
                Suspendisse congue velit in turpis lacinia, sit amet iaculis
                risus auctor. Ut consequat quis urna a suscipit. Morbi molestie
                quis elit et varius. In eget augue sem. Proin euismod augue
                orci, tincidunt gravida nisi vulputate at.
              </Typography>
              <Typography style={{ marginTop: 30 }}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Curabitur at eros eu quam vulputate porttitor non sed nunc. Duis
                vehicula consectetur sem, pellentesque porta ipsum tristique at.
                Donec id purus est. Donec est orci, ornare eu mattis semper,
                ullamcorper ac mi. Maecenas finibus tortor eget metus tincidunt,
                nec gravida nunc aliquet. Sed tincidunt facilisis purus eget
                rhoncus. Vestibulum sit amet felis magna. Sed non dolor eu nunc
                luctus vulputate. Cras congue, diam quis sodales fringilla,
                magna mi sollicitudin nisi, ac lobortis felis mauris non elit.
                Donec finibus sit amet dolor vel sodales. Maecenas odio urna,
                dictum non tortor eu, faucibus gravida purus.
              </Typography>
            </>
          }
        />
      )}
    </ThemeProvider>
  );
}

export default App;
