import { StyledDatePicker } from './styles';
import { formControlsChangeEvent } from '../../useFormControls';
import { TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

interface DatePickerProps extends Omit<unknown, 'onChange' | 'value'> {
  onChange: (e: formControlsChangeEvent) => void;
  value?: string | null;
  name: string;
  error?: boolean;
  helperText?: string;
}

const DatePicker: React.FC<DatePickerProps> = ({
  value,
  name,
  error,
  helperText,
  onChange,
  ...otherProps
}) => {
  const handleChange = (date: unknown) => {
    const fakeEvent = {
      target: {
        value: date,
        name,
      },
    } as formControlsChangeEvent;
    onChange(fakeEvent);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <StyledDatePicker
        label={'Data *'}
        minDate={new Date()}
        inputFormat='dd/MM/yyyy'
        value={value}
        onChange={handleChange}
        {...otherProps}
        renderInput={(params: any) => (
          <TextField
            style={{ width: '100%' }}
            {...params}
            error={error}
            helperText={helperText}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default DatePicker;
