import { styled } from '@mui/system';
import { colors } from '../../constants/theme/colors';

export const CarouselPage = styled('li')`
  font-size: 24px;
  margin-top: 15%;
  margin-bottom: 15%;
  color: ${colors.carosel.secondary};

  &.current {
    color: ${colors.carosel.primary};
  }
`;

export const CarouselDiv = styled('div')`
  display: flex;
  align-content: center;
  padding-left: 37%;
`;

