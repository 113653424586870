import {
  useState,
  ChangeEvent,
  MutableRefObject,
  useEffect,
  Dispatch,
  SetStateAction,
  useMemo,
  useRef,
} from 'react';

import { Grid, Typography } from '@mui/material';

import { formState, useFormControls } from '../useFormControls';
import {
  ButtonContainerFormTabs,
  FormButton,
  FormContainerFormTabs,
  MultiStepFormContainer,
  MultiStepFormTabs,
  NextButton,
  PreviousButton,
  TabDiv,
  VerifyButton,
} from './styles';
import * as yup from 'yup';

import { formConfig } from '../Form/Form';
import FormInputs from '../Form/FormInputs/FormInputs';

import { isMobile, BrowserView } from 'react-device-detect';
import { scroller } from 'react-scroll';

import { api, _api } from '../../services/Api';
import { ReactComponent as Details } from '../../assets/icons/details.svg';
import { ReactComponent as Calender } from '../../assets/icons/calendar.svg';
import { ReactComponent as Identification } from '../../assets/icons/identification.svg';
import { ReactComponent as Category } from '../../assets/icons/category.svg';

import { ReactComponent as DetailsActive } from '../../assets/icons/details_active.svg';
import { ReactComponent as CalenderActive } from '../../assets/icons/calendar_active.svg';
import { ReactComponent as IdentificationActive } from '../../assets/icons/identification_active.svg';
import { ReactComponent as CategoryActive } from '../../assets/icons/category_active.svg';

import { ReactComponent as Arrow } from '../../assets/icons/arrow.svg';
import { clientRoute } from '../../constants/Routes/routes';
import { getPeriod } from '../../utils/utils';
export interface FormTabConfig {
  label: any;
  mandatory?: boolean;
  formConfig: formConfig[];
  formState: formState;
}

interface confirmRequestBody {
  [key: string]: any;
}

const scrollTop = () => {
  scroller.scrollTo('form', {
    duration: 800,
    delay: 0,
    smooth: 'easeInOutQuart',
    offset: -100,
  });
};

const createKeyObject = <T,>(formValues: formState, defaultValue: T) => {
  const keys = Object.keys(formValues);
  const values: { [key: string]: T } = {};
  keys.forEach(e => {
    values[e] =
      typeof defaultValue === 'object' ? { ...defaultValue } : defaultValue;
  });
  return values;
};

const getTabValues = (formTabValues: formState, formValues: formState) => {
  const keys = Object.keys(formTabValues);
  const values: formState = {};
  keys.forEach(e => {
    values[e] = formValues[e];
  });
  return values;
};

const validateInput = (formValues: formState, element: formConfig) => {
  let error = { isValid: true, message: '' };
  try {
    let validation = element.validation;
    if (element.validation instanceof yup.BaseSchema) {
      let shape: Record<string, any> = {};
      shape[element.key] = element.validation;
      validation = yup.object().shape(shape);
    }
    validation.validateSync(formValues);
  } catch (e: any) {
    error.isValid = false;
    error.message = e.message;
  }
  return error;
};

const getValidation = (
  formValues: formState,
  element: formConfig,
  actualTabIndex?: number,
  userTypedField?: MutableRefObject<{ [key: string]: boolean }[]>
) => {
  let errors: { isValid: boolean; message: string }[] = [];
  if (
    formValues &&
    element.validation &&
    element.key &&
    element.type !== 'RadioButtonGroup' &&
    element.type !== 'ButtonGroup' &&
    element.type !== 'CheckboxGroup'
  ) {
    if (actualTabIndex !== undefined && userTypedField !== undefined) {
      if (
        !!formValues[element.key] &&
        !userTypedField.current[actualTabIndex][element.key]
      ) {
        userTypedField.current[actualTabIndex][element.key] = true;
      }

      if (userTypedField.current[actualTabIndex][element.key]) {
        const error = validateInput(formValues, element);
        errors.push(error);
      }
    } else {
      const error = validateInput(formValues, element);
      errors.push(error);
    }
  }

  return errors;
};

const getTabIssues = (
  formTab: FormTabConfig,
  formValues: formState,
  actualTabIndex?: number,
  userTypedField?: MutableRefObject<
    {
      [key: string]: boolean;
    }[]
  >
) => {
  let issues = 0;
  if (formTab.formConfig) {
    let allValidations: { isValid: boolean; message: string }[] = [];
    formTab.formConfig.forEach(async (element: any) => {
      const validation = getValidation(
        formValues,
        element,
        actualTabIndex,
        userTypedField
      );
      allValidations = allValidations.concat(validation);
    });
    issues = allValidations.filter(validation => !validation.isValid).length;
  }

  return issues;
};

const baseError = {
  isValid: true,
  message: '',
};

const checkCanEdit = (formTabs: FormTabConfig[], formValues: formState[]) => {
  const mandatoryForms = formTabs.reduce((prev, e, index) => {
    if (e.mandatory) {
      return [...prev, { ...e, index }];
    }
    return prev;
  }, [] as (FormTabConfig & { index: number })[]);
  let canEdit = true;
  if (!!mandatoryForms.length) {
    canEdit = !mandatoryForms.find(({ index, ...e }) => {
      return !!getTabIssues(e, formValues[index]);
    });
  }
  return canEdit;
};

const createInitialTabsState = (
  formTabs: FormTabConfig[],
  formValues: formState[]
) => {
  const canEdit = checkCanEdit(formTabs, formValues);
  return formTabs.map((e, i) => ({
    canEdit: e.mandatory ?? canEdit,
    selected: i === 0,
    issues: 0,
    errors: createKeyObject(e.formState, baseError),
  }));
};

const getKeysFromCheckboxObject = (checkboxObject: any) => {
  let result = [];
  for (const key in checkboxObject) {
    if (checkboxObject[key]) result.push(key);
  }
  return result;
};

const getSpecs = (detailTabForm: formState) => {
  let specs: any = [];

  for (const key in detailTabForm) {
    if (key !== 'files' && detailTabForm[key])
      if (detailTabForm[key].name) {
        specs.push({
          key: key,
          value: detailTabForm[key].name,
        });
      } else if (typeof detailTabForm[key] === 'string') {
        specs.push({
          key: key,
          value: detailTabForm[key],
        });
      } else {
        specs.push({
          key: key,
          value: getKeysFromCheckboxObject(detailTabForm[key]),
        });
      }
  }

  return JSON.stringify(specs);
};
interface MultiTabFormProps {
  formTabConfig: FormTabConfig[];
  formInitialValues: formState[];
  setFormCurrentValues: Dispatch<SetStateAction<formState[]>>;
  user: any;
  setUser: Dispatch<SetStateAction<any>>;
  addrForm: any;
  setSendTokenAgain: Dispatch<SetStateAction<boolean>>;
  editAddrForm: any;
  inputRefs: any;
}

const MultiTabForm = ({
  formTabConfig,
  formInitialValues,
  user,
  addrForm,
  setUser,
  setSendTokenAgain,
  setFormCurrentValues,
  editAddrForm,
  inputRefs,
}: MultiTabFormProps) => {
  const [tabs, setTabs] = useState(
    createInitialTabsState(formTabConfig, formInitialValues)
  );
  const [clickedNextButton, setClickedNextButton] = useState(false);

  const actualTabIndex = tabs.findIndex(t => t.selected);
  const actualTab = formTabConfig[actualTabIndex];
  const { formValues, handleFormChange } = useFormControls(
    formInitialValues,
    actualTabIndex
  );
  const userTypedField = useRef(formValues.map(e => createKeyObject(e, false)));

  const actualTabIssues = useMemo(() => {
    const issues = getTabIssues(actualTab, formValues[actualTabIndex]);
    return issues;
  }, [actualTab, actualTabIndex, formValues]);

  useEffect(() => {
    if (formValues) setFormCurrentValues(formValues);
  }, [formValues, setFormCurrentValues]);

  const navigateToTab = (tabIndex: number) => {
    const issues = getTabIssues(
      actualTab,
      formValues[actualTabIndex],
      actualTabIndex,
      userTypedField
    );

    setTabs(t =>
      t.map((e, i) => ({
        ...e,
        issues: e.selected ? issues : e.issues,
        selected: i === tabIndex,
      }))
    );
  };

  const handleChange = (event: ChangeEvent<{}>, index: number) => {
    navigateToTab(index);
  };

  const verifyCpfOrTel = async () => {
    setClickedNextButton(true);
    if (actualTabIssues === 0) {
      setClickedNextButton(false);
      try {
        const userResponse = await api.get(
          `/client/checkRegistration/${formValues[actualTabIndex].cpfOrTelephone}`
        );
        setUser(userResponse.data);
        navigateToTab(actualTabIndex + 1);
      } catch (e: any) {
        console.log(e);
      }
    }
  };

  const createUser = async () => {
    setClickedNextButton(true);
    if (actualTabIssues === 0) {
      const registerTabForm = formValues[4];
      const body = {
        clientInfo: {
          name: registerTabForm.name,
          email: registerTabForm.email,
          phone: registerTabForm.phone.replace(/\D/g, ''),
          docId: registerTabForm.docId.replace(/\D/g, ''),
          password: '123457',
          zipCode: registerTabForm.zipCode.replace(/\D/g, ''),
          addr: registerTabForm.addr,
          addrNumber: registerTabForm.addrNumber,
          extra: registerTabForm.extra,
          district: registerTabForm.district,
          city: registerTabForm.city,
          province: registerTabForm.province,
          usingTermsAccept: true,
          newsletter: false,
        },
      };
      try {
        await api.post('/landing-page', body).then(response => {
          setClickedNextButton(false);
          user.client = {
            id: response.data.message.idClient,
            addressId: response.data.message.idAddress,
          };
          setUser(user);
          navigateToTab(actualTabIndex + 1);
        });
      } catch (e: any) {
        alert(e.response.data.message.error);
      }
    }
  };

  const createAddress = async () => {
    setClickedNextButton(true);
    if (actualTabIssues === 0) {
      const body = {
        addressInfo: {
          clientId: user.client.id,
          zipCode: addrForm.zipCode.replace(/\D/g, ''),
          addr: addrForm.addr,
          addrNumber: addrForm.addrNumber,
          extra: addrForm.extra,
          district: addrForm.district,
          city: addrForm.city,
          province: addrForm.province,
        },
        idClient: user.client.id,
      };
      try {
        await api.post('/landing-page', body).then(response => {
          setClickedNextButton(false);
          user.client.addressId = response.data.message.idAddress;
          setUser(user);
          navigateToTab(actualTabIndex + 1);
        });
      } catch (e: any) {
        alert(e.response.data.message.error);
      }
    }
  };

  const getMegaBytes = (bytes: number) => bytes / 1024 ** 2;

  const sendFiles = async (files: File[], idBudgetRequest: string) => {
    const formData: string[] = [];

    files.filter((file: File) => {
      if (!file) return false;
      if (getMegaBytes(file.size) > 20) {
        return false;
      }

      return true;
    });

    files.forEach((file: File) => {
      const reader = new FileReader();

      reader.onloadend = async () => {
        if (reader.result?.toString()) {
          const body = {
            budgetId: idBudgetRequest,
            files: [{ infoFile: reader.result?.toString() }],
          };
          await _api
            .post('/awsFiles', body)
            .then(res => {})
            .catch(e => {
              console.log(e);
            });
        }
      };
      reader.readAsDataURL(file);
    });

    return formData;
  };

  const getAddressId = (registerTabForm: any) => {
    if (
      registerTabForm?.option === undefined ||
      registerTabForm.option === 'newAddress'
    )
      return user.client.addressId;

    return registerTabForm.option;
  };

  const getDetails = () => {
    let detailTab: { [key: string]: any } = {};
    formTabConfig[1].formConfig.forEach(element => {
      if (element.key !== 'files') {
        detailTab[element.key] = formValues[1][element.key];
      }
    });

    return detailTab;
  };

  const confirmRequest = async () => {
    const categoryTabForm = formValues[0];
    const detailTabForm = getDetails();
    const dateTabForm = formValues[2];
    const registerTabForm = formValues[4];
    //const tokenTabForm = formValues[5];

    if (user) {
      const body: confirmRequestBody = {
        budgetRequestInfo: {
          solicitationDate: dateTabForm.solicitationDate,
          solicitationPeriod: getPeriod(dateTabForm.period?.id),
          specs: getSpecs(formValues[1]),
          observation: dateTabForm.othersInfos,
          type: categoryTabForm.category,
          statusId: '1',
          subcategoryId: categoryTabForm.subCategory,
          budgetRequestFlag: 'L', // Identificar que vem da landing page
          requestAddressId: getAddressId(registerTabForm),
        },
        idClient: user.client?.id ?? '',
        idAddress: getAddressId(registerTabForm),
        // tokenInfo:
        //   tokenTabForm.tokenDigit1 +
        //   tokenTabForm.tokenDigit2 +
        //   tokenTabForm.tokenDigit3 +
        //   tokenTabForm.tokenDigit4,
      };

      if (editAddrForm.id) {
        body['alteredAddress'] = {
          ...editAddrForm,
          clientId: user.client?.id ?? '',
        };
      }

      await api
        .post('/landing-page/budget-request', body)
        .then(async res => {
          console.log(detailTabForm);
          if (
            formValues[1].files === undefined ||
            formValues[1].files?.length === 0
          ) {
            navigateToTab(actualTabIndex + 1);
          } else {
            await sendFiles(
              formValues[1].files,
              res.data.message.idBudgetRequest
            );
            navigateToTab(actualTabIndex + 1);
          }
        })
        .catch(e => {
          alert(e);
        });
    }
  };

  return (
    <>
      <BrowserView>
        <TabDiv>
          <div
            className={`tabDiv ${actualTabIndex === 0 ? 'active' : ''}`}
            style={{
              opacity:
                actualTabIndex >= 0 ? (actualTabIndex === 0 ? 1 : 0.8) : 0.4,
            }}
          >
            {actualTabIndex === 0 ? <CategoryActive /> : <Category />}
            <Typography>Categoria</Typography>
          </div>
          <Arrow style={{ transform: 'rotate(270deg) translateX(-10px)' }} />
          <div
            className={`tabDiv ${actualTabIndex === 1 ? 'active' : ''}`}
            style={{
              opacity:
                actualTabIndex >= 1 ? (actualTabIndex === 1 ? 1 : 0.8) : 0.4,
            }}
          >
            {actualTabIndex === 1 ? <DetailsActive /> : <Details />}
            <Typography>Detalhes</Typography>
          </div>
          <Arrow style={{ transform: 'rotate(270deg) translateX(-10px)' }} />
          <div
            className={`tabDiv ${actualTabIndex === 2 ? 'active' : ''}`}
            style={{
              opacity:
                actualTabIndex >= 2 ? (actualTabIndex === 2 ? 1 : 0.8) : 0.4,
            }}
          >
            {actualTabIndex === 2 ? <CalenderActive /> : <Calender />}
            <Typography>Data do serviço</Typography>
          </div>
          <Arrow style={{ transform: 'rotate(270deg) translateX(-10px)' }} />
          <div
            className={`tabDiv ${actualTabIndex >= 3 ? 'active' : ''}`}
            style={{
              opacity: actualTabIndex >= 3 ? 1 : 0.4,
            }}
          >
            {actualTabIndex >= 3 ? (
              <IdentificationActive />
            ) : (
              <Identification />
            )}
            <Typography>Identificação</Typography>
          </div>
        </TabDiv>
      </BrowserView>
      <MultiStepFormTabs
        id='form'
        variant='scrollable'
        value={actualTabIndex}
        onChange={handleChange}
      ></MultiStepFormTabs>
      <MultiStepFormContainer className={isMobile ? 'mobile' : ''}>
        {actualTab.formConfig && (
          <FormContainerFormTabs>
            {actualTab.label}
            <Grid container spacing={3} style={{ marginTop: 20 }}>
              <FormInputs
                key={actualTabIndex}
                config={actualTab.formConfig}
                getValidation={(element: formConfig) =>
                  getValidation(
                    formValues[actualTabIndex],
                    element,
                    actualTabIndex
                  )
                }
                clickedNextButton={clickedNextButton}
                formValues={getTabValues(
                  actualTab.formState,
                  formValues[actualTabIndex]
                )}
                handleFormChange={handleFormChange}
                inputRefs={inputRefs}
              />
            </Grid>
            {actualTabIndex === 3 && (
              <VerifyButton variant={'contained'} onClick={verifyCpfOrTel}>
                Verificar
              </VerifyButton>
            )}
            {actualTabIndex === 5 && (
              <>
                <FormButton variant={'contained'} onClick={confirmRequest}>
                  Confirmar Solicitação
                </FormButton>
                {/* <FormButton
                  variant={'text'}
                  onClick={() => {
                    setSendTokenAgain(true);
                  }}
                >
                  ENVIAR NOVAMENTE O CÓDIGO
                </FormButton> */}
                <FormButton
                  variant={'text'}
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  Cancelar
                </FormButton>
              </>
            )}
            {actualTabIndex === 6 && (
              <>
                <FormButton
                  variant={'contained'}
                  onClick={() => {
                    window.open(clientRoute);
                  }}
                >
                  Acessar Minhas Solicitações
                </FormButton>
                <FormButton
                  variant={'text'}
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  Enviar uma Nova Solicitação
                </FormButton>
              </>
            )}
            {actualTabIndex !== 5 && actualTabIndex !== 6 && (
              <ButtonContainerFormTabs>
                {actualTabIndex > 0 && (
                  <PreviousButton
                    variant={'text'}
                    onClick={() => {
                      scrollTop();
                      navigateToTab(actualTabIndex - 1);
                    }}
                  >
                    Anterior
                  </PreviousButton>
                )}
                {actualTabIndex !== 3 &&
                formValues &&
                formValues[0].category &&
                formValues[0].subCategory ? (
                  <NextButton
                    variant={'contained'}
                    onClick={() => {
                      scrollTop();
                      setClickedNextButton(true);
                      if (actualTabIndex === 4) {
                        if (!user?.exists) {
                          createUser();
                        } else if (formValues[4].option === 'newAddress') {
                          if (
                            addrForm.addr !== undefined &&
                            addrForm.addr !== '' &&
                            addrForm.city !== undefined &&
                            addrForm.city !== '' &&
                            addrForm.district !== undefined &&
                            addrForm.district !== '' &&
                            addrForm.province !== undefined &&
                            addrForm.province !== '' &&
                            addrForm.zipCode !== undefined &&
                            addrForm.zipCode !== ''
                          ) {
                            createAddress();
                          }
                        } else {
                          navigateToTab(actualTabIndex + 1);
                        }
                        setSendTokenAgain(true);
                      } else if (actualTabIssues === 0) {
                        navigateToTab(actualTabIndex + 1);
                        setClickedNextButton(false);
                      }
                    }}
                  >
                    Próximo
                  </NextButton>
                ) : null}
              </ButtonContainerFormTabs>
            )}
          </FormContainerFormTabs>
        )}
      </MultiStepFormContainer>
    </>
  );
};

export default MultiTabForm;
