import { useState, useEffect } from 'react';
import { FormTabConfig } from '../../components/MultiTabForm/MultiTabForm';
import { api } from '../../services/Api';
import * as yup from 'yup';

import { TypographyContainerFormTabs } from '../../components/MultiTabForm/styles';
import { formConfig } from '../../components/Form/Form';
import { validateMandatoryDropdown } from '../../utils/validations';

export const useDetailTab = (
  subCategoryId: number,
  detailTabState: any,
  setDetailTabState: React.Dispatch<React.SetStateAction<{}>>
) => {
  const [formConfiguration, setFormConfiguration] = useState<formConfig[]>([]);
  const [formElements, setFormElements] = useState();

  const addTextInputComponent = (element: any) => {
    return {
      key: element.title,
      type: 'TextInput',
      size: 12,
      validation: element.required
        ? yup.string().required('Campo Obrigatório')
        : undefined,
      textFieldProps: {
        label: element.title,
        required: element.required,
        inputProps: { maxLength: 30 },
      },
    };
  };

  const addCheckListComponent = (element: any) => {
    return {
      key: element.title,
      type: 'CheckboxGroup',
      title: element.title,
      size: 12,
      elements: element.children.map(
        (child: any, index: number) => child.title
      ),
    };
  };

  const addOptionsComponent = (element: any) => {
    return {
      key: element.title,
      type: 'Dropdown',
      size: 12,
      options: element.children.map((child: any, index: number) => ({
        id: index,
        name: child.title,
      })),
      validation: element.required
        ? validateMandatoryDropdown('Campo Obrigatório', element.title)
        : undefined,
      textFieldProps: {
        label: element.title,
        required: element.required,
      },
    };
  };

  useEffect(() => {
    const getForms = async () => {
      await api
        .get(`/forms/bySubCategory/${subCategoryId}`)
        .then(res => {
          if (
            res.data?.formInfo?.status === 'success' &&
            res.data.formInfo.data?.infoForm
          ) {
            setFormElements(JSON.parse(res.data.formInfo.data.infoForm));
          }
        })
        .catch(e => {
          console.log('Error', e.stack);
          console.log('Error', e.name);
          console.log('Error', e.message);
        });
    };
    getForms();
  }, [subCategoryId]);

  useEffect(() => {
    if (formElements) {
      const makeFormConfig = () => {
        const formElementsConfiguration = (formElements as any[])
          .map(element => {
            switch (element.type) {
              case 'Text':
                return addTextInputComponent(element);
              case 'Checklist':
                return addCheckListComponent(element);
              case 'Options':
                return addOptionsComponent(element);

              default:
                return null;
            }
          })
          .filter(e => e) as any;

        formElementsConfiguration.push({
          key: 'files',
          size: 12,
          type: 'Upload',
        } as any);

        const detailTabState: any = {};

        formElementsConfiguration.forEach((element: any) => {
          detailTabState[element.key as string] = undefined;
        });

        detailTabState['files'] = undefined;

        setDetailTabState(detailTabState);
        setFormConfiguration(formElementsConfiguration);
      };
      makeFormConfig();
    }
  }, [formElements, setDetailTabState]);

  const detailTab: FormTabConfig = {
    formState: detailTabState,
    mandatory: true,
    label: (
      <TypographyContainerFormTabs>
        Preencha com os detalhes.
      </TypographyContainerFormTabs>
    ),
    formConfig: formConfiguration,
  };

  return { detailTab, detailTabState } as const;
};
