import * as styles from './styles';

import { ReactComponent as logo } from '../../assets/images/logo.svg';
import { ReactComponent as professional } from '../../assets/images/professional-azul.svg';
import { ReactComponent as electric } from '../../assets/icons/electric.svg';
import { ReactComponent as paint } from '../../assets/icons/paint.svg';
import { ReactComponent as hydraulics } from '../../assets/icons/hydraulics.svg';
import { ReactComponent as cooling } from '../../assets/icons/cooling.svg';
import { ReactComponent as details } from '../../assets/icons/details.svg';
import { ReactComponent as presentation } from '../../assets/icons/presentation.svg';
import { ReactComponent as group } from '../../assets/icons/group.svg';
import { ReactComponent as arrow } from '../../assets/icons/arrow.svg';
import { ReactComponent as monitoring } from '../../assets/icons/monitoring.svg';
import { ReactComponent as client } from '../../assets/images/client.svg';
import { ReactComponent as professionalNetwork } from '../../assets/images/professionalNetwork.svg';
import { ReactComponent as step_one } from '../../assets/images/step_one.svg';
import { ReactComponent as step_two } from '../../assets/images/step_two.svg';
import { ReactComponent as step_three } from '../../assets/images/step_three.svg';
import { ReactComponent as step_four } from '../../assets/images/step_four.svg';

export const S = styles;
export const icons = {
  logo,
  professional,
  electric,
  paint,
  hydraulics,
  cooling,
  details,
  presentation,
  arrow,
  group,
  monitoring,
  client,
  professionalNetwork,
  step_one,
  step_two,
  step_three,
  step_four,
};
