export const getPeriod = (id: number) => {
  switch (id) {
    case 0:
      return 'morning';
    case 1:
      return 'afternoon';
    case 2:
      return 'night';
    default:
      return '';
  }
};
