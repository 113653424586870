import { ButtonProps } from '@mui/material';
import { buttonGroup } from '../Form';
import { StyledButton } from './styles';

interface ButtonGroupProps extends ButtonProps {
  value: string;
  name: string;
  onChange: any;
  buttonList: buttonGroup;
  style?: any;
}

const ButtonGroup = ({
  value,
  name,
  onChange,
  buttonList,
  style,
}: ButtonGroupProps) => {
  const createTargetEvent = (value: string) =>
    ({
      target: {
        value,
        name,
      },
    } as any);

  const handleChange = (value: string) => {
    const event = createTargetEvent(value);
    onChange(event);
  };

  return (
    <>
      {buttonList?.map((button, index) => (
        <StyledButton
          key={button.key}
          startIcon={button.startIcon}
          style={{ ...style, textTransform: 'none' }}
          name={button.name}
          variant={'contained'}
          color={value === button.key ? 'primary' : 'secondary'}
          onClick={() => {
            handleChange(button.key);
          }}
        >
          {button.label}
        </StyledButton>
      ))}
    </>
  );
};

export default ButtonGroup;
