import { useEffect, useState } from 'react';
import { FormTabConfig } from '../../components/MultiTabForm/MultiTabForm';
import { TypographyContainerFormTabs } from '../../components/MultiTabForm/styles';
import { formState } from '../../components/useFormControls';
import { api } from '../../services/Api';

export const userFoundTabState: formState = {
  option: 0,
  zipCode: '',
  addr: '',
  addrNumber: 0,
  extra: '',
  city: '',
  province: '',
};

export const useUserFoundTab = (
  user: any,
  addrForm: any,
  setAddrForm: React.Dispatch<React.SetStateAction<{}>>,
  setEditAddrForm: React.Dispatch<React.SetStateAction<{}>>,
  editAddrForm: any
) => {
  const [address, setAddress] = useState([]);

  const getAddress = async (userId: number) => {
    const response = await api.get(`/client/address/${userId}`);
    if (response.data[0]) userFoundTabState.option = response.data[0].id;
    setAddress(response.data);
  };

  useEffect(() => {
    if (user?.client) getAddress(user.client.id);
  }, [user]);

  const userFoundTab: FormTabConfig = {
    formState: userFoundTabState,
    mandatory: true,
    label: (
      <>
        <TypographyContainerFormTabs style={{ color: '#8CC63E' }}>
          Encontramos o cadastro!
        </TypographyContainerFormTabs>
        <TypographyContainerFormTabs style={{ color: '#4D4D4D' }}>
          Olá, {user?.client?.name ?? ''}
        </TypographyContainerFormTabs>
        <TypographyContainerFormTabs style={{ color: '#000000' }}>
          Endereços
        </TypographyContainerFormTabs>
        <TypographyContainerFormTabs style={{ color: '#4D4D4D' }}>
          Selecione o endereço que será feito o serviço. Se você preferir, pode
          adicionar um novo endereço preenchendo o formulário.
        </TypographyContainerFormTabs>
      </>
    ),
    formConfig: [
      {
        key: 'option',
        type: 'RadioButtonGroup',
        size: 12,
        address: address,
        addrForm: addrForm,
        setAddrForm: setAddrForm,
        editAddrForm: editAddrForm,
        setEditAddrForm: setEditAddrForm,
      },
    ],
  };

  return { userFoundTab } as const;
};
