export const colors = {
  primary: '#0446F5',
  secondary: '#F2F2F2',
  text: {
    primary: '#4D4D4D',
    emphasys: '#0446F5',
    link: '#0446F5',
    secondary: '#4D4D4DFA',
    button: '#FFFFFF',
    success: '#8CC63E',
    fail: '#D80B1A',
  },

  carosel: {
    primary: '#4D4D4D',
    secondary: '#A6A6A6',
  },

  div: {
    primary: '#FFFF',
    secondary: '#F2F2F2',
  },
};
