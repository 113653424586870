import ReactSwipe from 'react-swipe';
import { useState } from 'react';
import * as S from './styles';

type Props = {
    children?: Array<any | any[]>;
};

const Carousel = ( { children }:Props)  =>{
  const [carouselIndex, setCarouselIndex] = useState(0);
  return (<>
    <ReactSwipe
        className="carousel"
        swipeOptions={{ continuous: false, callback: (index: number, elem: HTMLElement)=> setCarouselIndex(index), startSlide: carouselIndex }}
    >
        {children}
    </ReactSwipe>
    <S.CarouselDiv>
        <S.CarouselPage className={carouselIndex === 0 ? 'current' : ''}/>
        <S.CarouselPage className={carouselIndex === 1 ? 'current' : ''}/>
        <S.CarouselPage className={carouselIndex === 2 ? 'current' : ''}/>
        <S.CarouselPage className={carouselIndex === 3 ? 'current' : ''}/>
    </S.CarouselDiv>
    </>
  )
}

export default Carousel;
