import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Button } from '@mui/material';
import {
  Divider,
  FileErrorMessage,
  FileName,
  FileNameDiv,
  UploadDescription,
  UploadDiv,
  UploadTitle,
} from './styles';
import { Check, Close, DeleteOutlined } from '@mui/icons-material';

const Input = styled('input')({
  display: 'none',
});

interface UploadProps {
  value: any;
  name: string;
  onChange: any;
}

const Upload: React.FC<UploadProps> = ({ value, name, onChange }) => {
  const createTargetEvent = (value: any) =>
    ({
      target: {
        value,
        name,
      },
    } as any);

  const getMegaBytes = (bytes: number) => bytes / 1024 ** 2;

  const fileIsValid = (file: any) => {
    if (getMegaBytes(file.size) > 20) {
      return 'Tamanho excede o permitido (20 MB).';
    }

    return;
  };

  const handleChange = (e: any) => {
    if (!!e.target.files) {
      const event = createTargetEvent(Array.from(e.target.files));
      onChange(event);
    }
  };

  const handleRemove = (index: number) => {
    value.splice(index, 1);
    onChange({
      target: {
        value: value,
        name,
      },
    });
  };

  return (
    <Box
      component='div'
      sx={{
        border: '1px solid grey',
        borderRadius: 1,
        display: 'flex',
        alignItems: 'center',
        padding: 2,
        flexDirection: 'column',
      }}
    >
      <UploadDiv>
        <UploadTitle> Anexos</UploadTitle>
        <label
          htmlFor='contained-button-file'
          style={{ marginRight: 0, marginLeft: 'auto' }}
        >
          <Input
            accept='image/png, image/jpeg'
            id='contained-button-file'
            multiple
            type='file'
            onChange={handleChange}
          />
          <Button
            style={{
              fontStyle: 'normal',
              fontWeight: 600,
              fontSize: '12px',
              lineHeight: '16px',

              textAlign: 'center',
              letterSpacing: ' 1.25px',
              textTransform: 'uppercase',
            }}
            variant='text'
            component='span'
          >
            SELECIONAR ARQUIVOS
          </Button>
        </label>
      </UploadDiv>
      {!value || value?.length === 0 ? (
        <UploadDescription>
          Você pode anexar fotos para facilitar o orçamento. Tamanho máximo de
          20 MB cada arquivo.
        </UploadDescription>
      ) : (
        value?.map((file: any, index: number) => (
          <>
            <FileName key={file.name}>
              {!fileIsValid(file) ? (
                <FileNameDiv>
                  <Check
                    fontSize='small'
                    style={{
                      color: '#8CC63E',
                      marginRight: 10,
                    }}
                  />
                  {file.name}
                </FileNameDiv>
              ) : (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <FileNameDiv>
                    <Close
                      fontSize='small'
                      style={{
                        color: '#D80B1A',
                        marginRight: 10,
                      }}
                    />
                    {file.name}
                  </FileNameDiv>
                  <FileErrorMessage>{fileIsValid(file)}</FileErrorMessage>
                </div>
              )}
              <DeleteOutlined
                onClick={() => {
                  handleRemove(index);
                }}
                style={{
                  color: '#939598',
                  cursor: 'pointer',
                  marginRight: 0,
                  marginLeft: 'auto',
                }}
              />
            </FileName>
            {index + 1 !== value?.length && <Divider />}
          </>
        ))
      )}
    </Box>
  );
};

export default Upload;
