import { FormTabConfig } from '../../components/MultiTabForm/MultiTabForm';
import { formState, useFormControls } from '../../components/useFormControls';

import * as yup from 'yup';
import { CEP_MASK, CPF_MASK, PHONE_MASK } from '../../utils/masks';
import {
  validateCEP,
  validateCPF,
  validateEmail,
  validatePhone,
} from '../../utils/validations';
import { TypographyContainerFormTabs } from '../../components/MultiTabForm/styles';
import { useEffect, useState } from 'react';
import { getFullAddress } from '../../utils/cep';

export const userNotFoundTabState: formState = {
  name: '',
  docId: '',
  email: '',
  phone: '',
  zipCode: '',
  addr: '',
  addrNumber: null,
  extra: '',
  city: '',
  district: '',
  province: '',
};

export const useUserNotFoundTab = (formCurrentValues: formState[]) => {
  const [fullAddress, setFullAddress] = useState<any>({});
  const { setFormValues } = useFormControls(formCurrentValues, 4);

  useEffect(() => {
    if (fullAddress) {
      formCurrentValues[4].district = fullAddress.bairro;
      formCurrentValues[4].city = fullAddress.localidade;
      formCurrentValues[4].addr = fullAddress.logradouro;
      formCurrentValues[4].province = fullAddress.uf;
      setFormValues(s =>
        s.map((e: formState, i: number) =>
          i === 4
            ? {
                ...e,
                district: fullAddress.bairro,
                city: fullAddress.bairro,
                addr: fullAddress.logradouro,
                province: fullAddress.uf,
              }
            : e
        )
      );
    }
  }, [formCurrentValues, fullAddress, setFormValues]);

  useEffect(() => {
    if (formCurrentValues && formCurrentValues[4]?.zipCode) {
      const fetchCep = async () => {
        setFullAddress(await getFullAddress(formCurrentValues[4].zipCode));
      };

      fetchCep();
    }
  }, [formCurrentValues]);

  const userNotFoundTab: FormTabConfig = {
    formState: userNotFoundTabState,
    mandatory: true,
    label: (
      <>
        <TypographyContainerFormTabs
          style={{ color: '#D80B1A', marginBottom: 30 }}
        >
          Não encontramos o seu cadastro!
        </TypographyContainerFormTabs>
        <TypographyContainerFormTabs>
          Por favor, preencha o formulário.
        </TypographyContainerFormTabs>
        <TypographyContainerFormTabs style={{ color: '#000', marginTop: 30 }}>
          Suas informações
        </TypographyContainerFormTabs>
      </>
    ),
    formConfig: [
      {
        key: 'name',
        type: 'TextInput',
        size: 12,
        validation: yup.string().required('Campo Obrigatório'),
        textFieldProps: {
          label: 'Nome completo',
          required: true,
        },
      },
      {
        key: 'docId',
        type: 'TextInput',
        size: 12,
        validation: yup.string().test('docId', 'CPF Inválido', validateCPF),
        mask: CPF_MASK,
        textFieldProps: {
          label: 'CPF',
          inputProps: {
            maxLength: 11,
            type: 'number',
          },
          required: true,
        },
      },
      {
        key: 'email',
        type: 'TextInput',
        size: 12,
        validation: yup
          .string()
          .test('email', 'E-mail Inválido', validateEmail),
        textFieldProps: {
          label: 'E-mail',
          required: true,
        },
      },
      {
        key: 'phone',
        type: 'TextInput',
        size: 12,
        validation: yup
          .string()
          .test('phone', 'Celular Inválido', validatePhone),
        mask: PHONE_MASK,
        textFieldProps: {
          label: 'Celular',
          required: true,
          inputProps: {
            type: 'number',
            maxLength: 11,
          },
        },
      },
      {
        key: 'addLabel',
        type: 'Label',
        size: 12,
        label: 'Endereço',
      },
      {
        key: 'zipCode',
        type: 'TextInput',
        size: 12,
        validation: yup.string().test('zipCode', 'CEP Inválido', validateCEP),
        mask: CEP_MASK,
        textFieldProps: {
          label: 'CEP',
          required: true,
          inputProps: {
            type: 'number',
            maxLength: 8,
          },
        },
      },
      {
        key: 'addr',
        type: 'TextInput',
        size: 12,
        validation: yup.string().required('Campo Obrigatório'),
        textFieldProps: {
          label: 'Endereço',
          required: true,
        },
      },
      {
        key: 'addrNumber',
        type: 'TextInput',
        size: 12,
        validation: yup.number().typeError('Campo Obrigatório'),
        textFieldProps: {
          label: 'Número',
          required: true,
          type: 'number',
        },
      },
      {
        key: 'district',
        type: 'TextInput',
        size: 12,
        validation: yup.string().required('Campo Obrigatório'),
        textFieldProps: {
          label: 'Bairro',
          required: true,
        },
      },
      {
        key: 'extra',
        type: 'TextInput',
        size: 12,
        textFieldProps: {
          label: 'Complemento',
        },
      },
      {
        key: 'city',
        type: 'TextInput',
        size: 12,
        validation: yup.string().required('Campo Obrigatório'),
        textFieldProps: {
          label: 'Cidade',
          required: true,
        },
      },
      {
        key: 'province',
        type: 'TextInput',
        size: 12,
        validation: yup.string().required('Campo Obrigatório'),
        textFieldProps: {
          label: 'UF',
          required: true,
        },
      },
    ],
  };

  return { userNotFoundTab } as const;
};
